import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import axios from 'axios'
import VueAxios from 'vue-axios'


Vue.config.productionTip = false

Vue.use(VueAxios, axios)

//globale

// Vue.prototype.__BASE_URL = 'http://localhost:3000';

//DEV
// Vue.prototype.__BASE_URL_NODIP = 'http://localhost:3000';
// Vue.prototype.__BASE_URL_CORP = 'http://localhost:3000';

//PROD
Vue.prototype.__BASE_URL_NODIP = 'http://dev.docker.it.grouperci.com:10030';
Vue.prototype.__BASE_URL_CORP = 'http://dev.docker.it.grouperci.com/rest@private-lease-it/rest';

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')