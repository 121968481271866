
export default {
    methods: {
        __PATCH_DOCUMENTI(url, documentId, documentType, proposalId, documents_info, index) {

            console.log(url)
            console.log(documentId)
            console.log(documentType)
            console.log(proposalId)

            console.log('patch: ' + this.__BASE_URL_CORP + '/proposals/' + proposalId + '/documents/' + documentId)
            // this.axios.get(this.__BASE_URL_CORP + '/documentId', {
            this.axios.patch(this.__BASE_URL_CORP + '/proposals/' + proposalId + '/documents/' + documentId, {    //TODO - rimettere e cancellare sopra
                'x-api-version': 'hydrogen',
                'document': {
                    "attachments": [{
                        "attachmentDownloadLink": url,
                        "attachmentId": documentId,
                        "attachmentStatus": "SCANNED_OK"
                    }],
                    "documentId": documentId,
                    "documentType": documentType
                },
                'documentId': documentId,
                'proposalId': proposalId
            })
                .then(response => {
                    console.log(response)

                    //1 - da codice fiscale
                    if (index == 0) {
                        //Salva la patente
                        this.request__PATENTE(proposalId, documents_info, 'PA');
                    }

                    //2 - da patente
                    if (index == 1) {
                        //Salva il contratto
                        this.request__CONTRATTO(proposalId, documents_info, 'RC');
                    }

                    //3 - da contratto
                    if (index == 2) {
                        //Ultima chiamata - conferma la prenotazione
                        this.__PUT_PRENOTAZIONI();
                    }

                }).catch(error => {
                    console.log(error)
                }).finally(() => {
                    // console.log('fine')
                });
        }
    }
};