import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    car: {
      info: [],
      countdown: [],
      id_prenotazione: 0
    },
    user: {
      info: [],
      user_id: '',
      email: ''
    },
    formFields: {
      valid: false
    },
    prospect: {
      id: 0
    },
    prospect_sub_ids: {
      ids: []
    },
    proposal: {
      id: 0
    },
    patch: {
      value: false
    },
    confirmedPrenotation: {
      value: false
    }
  },
  mutations: {

    SET_CAR(state, value) {
      state.car.info = {
        index: value.index,
        indice_offerta: value.indice_offerta,
        //varianti
        idOfferta: value.idOfferta,
        disponibilita: value.disponibilita,
        immagine: value.immagine,
        opzioni_idOpzione: value.opzioni_idOpzione,
        opzioni_codice: value.opzioni_codice,
        opzioni_descrizione: value.opzioni_descrizione,
        opzioni_lordo: value.opzioni_lordo,
        opzioni_margine: value.opzioni_margine,
        opzioni_netto: value.opzioni_netto,
        //stampati
        codiceAggregazioneOfferta: value.codiceAggregazioneOfferta,
        modello: value.modello,
        versione: value.versione,
        autoNetto: value.autoNetto,
        servizi: value.servizi,
        canoneMensile: value.canoneMensile,
        //altri
        canaleVendita: value.canaleVendita,
        canone_idCanone: value.canone_idCanone,
        canone_autoLordo: value.canone_autoLordo,
        canone_totaleLordo: value.canone_totaleLordo,
        chilometri: value.chilometri,
        codice: value.codice,
        codiceConcessionario: value.codiceConcessionario,
        codiceProntuario: value.codiceProntuario,
        descrizione: value.descrizione,
        durata: value.durata,
        ecoBonus: value.ecoBonus,
        ipt: value.ipt,
        ivaVeicolo: value.ivaVeicolo,
        mss: value.mss,
        mssPfu: value.mssPfu,
        nettoConOpzioni: value.nettoConOpzioni,
        nettoVeicolo: value.nettoVeicolo,
        sconto_idSconto: value.sconto_idSconto,
        sconto_importo: value.sconto_importo,
        sconto_percentuale: value.sconto_percentuale,
        servizi_idServizi: value.servizi_idServizi,
        servizi_pcl: value.servizi_pcl,
        servizi_pdu: value.servizi_pdu,
        servizi_prontuario: value.servizi_prontuario,
        tassoOfferta: value.tassoOfferta,
        validita_fine: value.validita_fine,
        validita_inizio: value.validita_inizio,
        valoreResiduo_correzione: value.valoreResiduo_correzione,
        valoreResiduo_importo: value.valoreResiduo_importo,
        valoreResiduo_percentuale: value.valoreResiduo_percentuale,
        veicolo_idVeicolo: value.veicolo_idVeicolo,
        veicolo_alimentazione_key: value.veicolo_alimentazione_key,
        veicolo_alimentazione_value: value.veicolo_alimentazione_value,
        veicolo_brand: value.veicolo_brand,
        veicolo_modello_key: value.veicolo_modello_key,
        veicolo_potenza: value.veicolo_potenza,
        veicolo_utilizzo: value.veicolo_utilizzo,
        veicolo_versione_key: value.veicolo_versione_key
      }
    },

    SET_IDPRENOTATION(state, value) {
      state.car.id_prenotazione = value
    },

    SET_COUNTDOWN_CAR(state, value) {
      state.car.countdown = {
        countdown_car: value
      }
    },

    SET_USER(state, value) {
      state.user.info = {
        statocivile: value.statocivile,
        nome: value.nome,
        cognome: value.cognome,
        datadinascita: value.datadinascita,
        codicefiscale: value.codicefiscale,
        telefono: value.telefono,
        codiceiban: value.codiceiban,
        titolarecontocorrente: value.titolarecontocorrente,
        indirizzo: value.indirizzo,
        ncivico: value.ncivico,
        provincia: value.provincia,
        codicepostale: value.codicepostale,
        localita: value.localita,
        paeseresidenza: value.paeseresidenza,
        targavettura: value.targavettura,
        filialeritiro: value.filialeritiro
      }
    },

    SET_USER_ID(state, value) {
      state.user.user_id = value;
    },

    SET_USER_EMAIL(state, value) {
      state.user.email = value;
    },

    SET_FORM_FIELDS_VALID(state, value) {
      state.formFields.valid = value;
    },

    SET_PROSPECT_ID(state, value) {
      state.prospect.id = value;
    },

    SET_PROSPECT_SUB_IDS(state, value) {
      state.prospect_sub_ids.ids = {
        contactId_mobile: value.contactId_mobile,
        contactId_email: value.contactId_email,
        addressId_domiciliary: value.addressId_domiciliary,
        addressId_residential: value.addressId_residential,
        employmentId: value.employmentId,
        contactId: value.contactId,
        addressId: value.addressId,
        bankAccountId: value.bankAccountId,
        optinId_condizioni_generali: value.optinId_condizioni_generali,
        optinId_marketing: value.optinId_marketing,
        optinId_third_party: value.optinId_third_party,
        optinid_credit_transfer: value.optinid_credit_transfer,
        optinid_data_treatment: value.optinid_data_treatment
      }
    },

    SET_PROPOSAL_ID(state, value) {
      state.proposal.id = value;
    },

    SET_PATCH(state, value) {
      state.patch.value = value;
    },

    SET_CONFIRMED_PRENOTATION(state, value) {
      state.confirmedPrenotation.value = value;
    }
  },
  actions: {

    setCar(context, value) {
      context.commit('SET_CAR', value);
    },

    setIdPrenotation(context, value) {
      context.commit('SET_IDPRENOTATION', value);
    },

    setCountdownCar(context, value) {
      context.commit('SET_COUNTDOWN_CAR', value);
    },

    setUser(context, value) {
      context.commit('SET_USER', value);
    },

    setUserId(context, value) {
      context.commit('SET_USER_ID', value);
    },

    setUserEmail(context, value) {
      context.commit('SET_USER_EMAIL', value);
    },

    setFormFieldsValid(context, value) {
      context.commit('SET_FORM_FIELDS_VALID', value);
    },

    setProposalId(context, value) {
      context.commit('SET_PROPOSAL_ID', value);
    },

    setProspectId(context, value) {
      context.commit('SET_PROSPECT_ID', value);
    },

    setProspectSubIds(context, value) {
      context.commit('SET_PROSPECT_SUB_IDS', value);
    },

    setPatch(context, value) {
      context.commit('SET_PATCH', value);
    },

    setConfirmedPrenotation(context, value) {
      context.commit('SET_CONFIRMED_PRENOTATION', value);
    }
  },
  modules: {
  }
})
