import $ from 'jquery'

export default {
    methods: {
        __DELETE_PRENOTAZIONE() {

            //controllo se un auto era gia stata selezionata risblocca la vettura
            let id_Prenotazione_old = this.$store.state.car.id_prenotazione;
            console.log('id prenotazione: ' + id_Prenotazione_old)

            if (id_Prenotazione_old != 0) {

                //Elimina l'impegno di un veicolo
                console.log('delete: ' + this.__BASE_URL_NODIP + '/prenotazioni/' + id_Prenotazione_old)
                // this.axios.get(this.__BASE_URL_NODIP + '/delete_prenotazione/')
                    this.axios.delete(this.__BASE_URL_NODIP + '/prenotazioni/' + id_Prenotazione_old)  //TODO - rimettere e cancellare sopra
                    .then(response => {
                        console.log(response)
                        console.log('prenotazione eliminata: ' + id_Prenotazione_old)

                        //impostandolo a false viene eseguita la POST e non più la patch
                        this.$store.dispatch('setPatch', false);

                        //passa alla pagina home con parametro
                        this.$router.push({
                            name: 'Home',
                            params: {
                                action: '__REINIT_OFFERTE'
                            }
                        }).then(() => {
                            window.scrollTo(0, 0);
                            //menu class
                            $('.list-step li a').removeClass('active').removeClass('passed');
                            $('.list-step li:nth-child(1) a').addClass('active');

                        }).catch((error) => {
                            console.log(error)
                        });

                    }).catch(error => {
                        console.log(error)
                    }).finally(() => {
                        // console.log('fine')
                    });
            }
        }
    }
};