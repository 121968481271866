<template>

    <div class="informations">
        
        <dl class="accordion">
        
            <dt @click="toggleAccordion($event)"><button>Regolamento</button></dt>
            <dd>
                <p><strong>Nuova Gamma Renault CAPTUR PLUG-IN HYBRID. Emissioni di CO₂: da 32 a 35 g/km. Consumi (ciclo misto): da 1,4 a 1,6 l/100 km. Emissioni e consumi omologati secondo la normativa comunitaria vigente. Foto non rappresentativa del prodotto. Offerta valida presso la Rete aderente fino al 31/03/2022.</strong></p>
                <p>*renault Easy: esempio di finanziamento riferito a nuovo captur intens plug-in e-tech hybrid 160 a € 29.400 (iva inclusa, ipt e contributo pfu esclusi) valido in caso di ritiro o rottamazione di un veicolo usato di proprietà del cliente da almeno sei mesi: anticipo € 6.300, importo totale del credito € 25.140,08 (include finanziamento veicolo € 23.100 e, in caso di adesione, di finanziamento protetto € 1.041,08 e pack service a € 999 comprensivo di 3 anni di furto e incendio, 1 anno di driver insurance, estensione di garanzia 3 anni o 60.000 km ); spese istruttoria pratica € 300 + imposta di bollo € 62,85 (addebitata sulla prima rata), interessi € 679,67, valore futuro garantito € 19.380,00 (rata finale), per un chilometraggio totale massimo di 30.000 km; in caso di restituzione del veicolo eccedenza chilometrica 0,10 euro/km; importo totale dovuto dal consumatore € 25.819,74 in 36 rate da € 178,88 oltre la rata finale. tan 0,99% (tasso fisso), taeg 1,75%, spese di incasso mensili € 3, spese per invio rendiconto periodico (annuale) € 1,20 (diversamente on line gratuito) oltre imposta di bollo pari a € 2. salvo approvazione finrenault. documentazione precontrattuale ed assicurativa disponibile presso i punti vendita della rete Renault convenzionati finrenault e sul sito finren.it. messaggio pubblicitario con finalità promozionale. offerta della rete Renault che aderisce all'iniziativa valida fino al 31/03/2022.</p>
                <a class="btn-download" href="#">scarica il regolamento</a>
            </dd>

            <dt @click="toggleAccordion($event)"><button>FAQ</button></dt>
            <dd>
                <p><strong>Nuova Gamma Renault CAPTUR PLUG-IN HYBRID. Emissioni di CO₂: da 32 a 35 g/km. Consumi (ciclo misto): da 1,4 a 1,6 l/100 km. Emissioni e consumi omologati secondo la normativa comunitaria vigente. Foto non rappresentativa del prodotto. Offerta valida presso la Rete aderente fino al 31/03/2022.</strong></p>
                <p>*renault Easy: esempio di finanziamento riferito a nuovo captur intens plug-in e-tech hybrid 160 a € 29.400 (iva inclusa, ipt e contributo pfu esclusi) valido in caso di ritiro o rottamazione di un veicolo usato di proprietà del cliente da almeno sei mesi: anticipo € 6.300, importo totale del credito € 25.140,08 (include finanziamento veicolo € 23.100 e, in caso di adesione, di finanziamento protetto € 1.041,08 e pack service a € 999 comprensivo di 3 anni di furto e incendio, 1 anno di driver insurance, estensione di garanzia 3 anni o 60.000 km ); spese istruttoria pratica € 300 + imposta di bollo € 62,85 (addebitata sulla prima rata), interessi € 679,67, valore futuro garantito € 19.380,00 (rata finale), per un chilometraggio totale massimo di 30.000 km; in caso di restituzione del veicolo eccedenza chilometrica 0,10 euro/km; importo totale dovuto dal consumatore € 25.819,74 in 36 rate da € 178,88 oltre la rata finale. tan 0,99% (tasso fisso), taeg 1,75%, spese di incasso mensili € 3, spese per invio rendiconto periodico (annuale) € 1,20 (diversamente on line gratuito) oltre imposta di bollo pari a € 2. salvo approvazione finrenault. documentazione precontrattuale ed assicurativa disponibile presso i punti vendita della rete Renault convenzionati finrenault e sul sito finren.it. messaggio pubblicitario con finalità promozionale. offerta della rete Renault che aderisce all'iniziativa valida fino al 31/03/2022.</p>
            </dd>

            <dt @click="toggleAccordion($event)"><button>Regolamento</button></dt>
            <dd>
                <p><strong>Nuova Gamma Renault CAPTUR PLUG-IN HYBRID. Emissioni di CO₂: da 32 a 35 g/km. Consumi (ciclo misto): da 1,4 a 1,6 l/100 km. Emissioni e consumi omologati secondo la normativa comunitaria vigente. Foto non rappresentativa del prodotto. Offerta valida presso la Rete aderente fino al 31/03/2022.</strong></p>
                <p>*renault Easy: esempio di finanziamento riferito a nuovo captur intens plug-in e-tech hybrid 160 a € 29.400 (iva inclusa, ipt e contributo pfu esclusi) valido in caso di ritiro o rottamazione di un veicolo usato di proprietà del cliente da almeno sei mesi: anticipo € 6.300, importo totale del credito € 25.140,08 (include finanziamento veicolo € 23.100 e, in caso di adesione, di finanziamento protetto € 1.041,08 e pack service a € 999 comprensivo di 3 anni di furto e incendio, 1 anno di driver insurance, estensione di garanzia 3 anni o 60.000 km ); spese istruttoria pratica € 300 + imposta di bollo € 62,85 (addebitata sulla prima rata), interessi € 679,67, valore futuro garantito € 19.380,00 (rata finale), per un chilometraggio totale massimo di 30.000 km; in caso di restituzione del veicolo eccedenza chilometrica 0,10 euro/km; importo totale dovuto dal consumatore € 25.819,74 in 36 rate da € 178,88 oltre la rata finale. tan 0,99% (tasso fisso), taeg 1,75%, spese di incasso mensili € 3, spese per invio rendiconto periodico (annuale) € 1,20 (diversamente on line gratuito) oltre imposta di bollo pari a € 2. salvo approvazione finrenault. documentazione precontrattuale ed assicurativa disponibile presso i punti vendita della rete Renault convenzionati finrenault e sul sito finren.it. messaggio pubblicitario con finalità promozionale. offerta della rete Renault che aderisce all'iniziativa valida fino al 31/03/2022.</p>
            </dd>
        </dl>

    </div>

</template>

<script>
import $ from 'jquery'

export default {
    name: 'Informations',

    methods: {

      toggleAccordion: function (e) {
            var $this = $(e.target);
            if (!$this.hasClass('active')) {
                $this.addClass('active');
                $this.next().slideDown('fast');
            } else {
                $this.removeClass('active');
                $this.next().slideUp('fast');
            }
      }
    }
}
</script>

<style scoped>
   
</style>