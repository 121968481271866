<template>
<div id="form">

    <CarInfo />

    <div class="row">
        <div class="row-form">

            <form class="form_main" @submit="onSubmit">

                <dl class="accordion-form">

                    <!-- Informazioni personali -->
                    <dt @click.stop.prevent="toggleForm($event)" id="step_form-1" class="clickable">
                        <button>Informazioni personali</button>
                        <div class="accordion-step">
                            <span>1/9</span>
                            <span class="modify-form-step">modifica</span>
                        </div>
                    </dt>
                    <dd>
                        <div class="ct-input">

                            <div class="form-control">
                                <label for="ipn">Ipn*</label>
                                <input id="ipn" v-model="form.ipn" type="text" placeholder="" />
                                <p class="error" v-show="error.ipn">{{ message.camporichiesto }}</p>
                                <p class="error" v-show="error.ipnvalido">{{ error.ipn_msg }}</p>
                            </div>

                            <div class="form-control">
                                <label for="ipn">Inserisci la tua mail aziendale*</label>
                                <input id="ipn" v-model="form.email" type="email" placeholder="" />
                                <p class="error" v-show="error.email">{{ message.camporichiesto }}</p>
                                <p class="error" v-show="error.emailnonvalida">{{ error.emailnonvalida_msg }}</p>
                            </div>

                            <div class="form-control">
                                <label for="ipn">Conferma mail*</label>
                                <input id="ipn" v-model="form.confermaemail" type="email" placeholder="" />
                                <p class="error" v-show="error.confermaemail">{{ message.camporichiesto }}</p>
                                <p class="error" v-show="error.confermaemailnonvalida">{{ error.emailnonvalida_msg }}</p>
                                <p class="error" v-show="error.emailcorrispondenti">{{ error.email_msg }}</p>
                            </div>

                            <div class="form-control">
                                <select id="statocivile" v-model="form.statocivile">
                                    <option disabled value="">Stato civile*</option>
                                    <option value="MALE">Signor</option>
                                    <option value="FEMALE">Sig.ra</option>
                                </select>
                                <p class="error" v-show="error.statocivile">{{ message.camporichiesto }}</p>
                            </div>

                            <div class="form-control">
                                <label for="nome">Nome*</label>
                                <input id="nome" v-model="form.nome" type="text" placeholder="" />
                                <p class="error" v-show="error.nome">{{ message.camporichiesto }}</p>
                            </div>

                            <div class="form-control">
                                <label for="cognome">Cognome*</label>
                                <input id="cognome" v-model="form.cognome" type="text" placeholder="" />
                                <p class="error" v-show="error.cognome">{{ message.camporichiesto }}</p>
                            </div>

                            <div class="form-control">
                                <label for="datadinascita">Data di nascita*</label>
                                <input id="datadinascita" v-model="form.datadinascita" type="date" placeholder="gg/mm/aaaa" />
                                <p class="error" v-show="error.datadinascita">{{ message.camporichiesto }}</p>
                            </div>

                            <div class="form-control">
                                <label for="codicefiscale">Codice fiscale*</label>
                                <input id="codicefiscale" v-model="form.codicefiscale" type="text" placeholder="" />
                                <p class="error" v-show="error.codicefiscale">{{ message.camporichiesto }}</p>
                                <p class="error" v-show="error.codicefiscalevalido">{{ error.codicefiscale_msg }}</p>
                            </div>

                            <div class="form-control">
                                <label for="telefono">Telefono*</label>
                                <input id="telefono" v-model="form.telefono" type="tel" placeholder="" @keypress="isNumber($event)" />
                                <p class="error" v-show="error.telefono">{{ message.camporichiesto }}</p>
                                <p class="info-input">Per convalidare il tuo account, ti invieremo un codice via SMS a questo numero.</p>
                            </div>

                            <button class="btn-2 btn-next-step" @click.stop.prevent="nextStep(1)">prosegui</button>
                        </div>
                    </dd>

                    <!-- Nucleo familiare -->
                    <dt @click.stop.prevent="toggleForm($event)" id="step_form-2">
                        <button>Nucleo familiare</button>
                        <div class="accordion-step">
                            <span>2/9</span>
                            <span class="modify-form-step">modifica</span>
                        </div>
                    </dt>
                    <dd>
                        <div class="ct-input">

                            <div class="form-control">
                                <select id="nucleostatocivile" v-model="form.nucleostatocivile">
                                    <option disabled value="">Stato civile*</option>
                                    <option v-for="option in form.options_nucleostatocivile" :value="option.key" :key="option.key">
                                        {{ option.value }}
                                    </option>
                                </select>
                                <p class="error" v-show="error.nucleostatocivile">{{ message.camporichiesto }}</p>
                            </div>

                            <div class="form-control">
                                <label for="nucleocomponenti">Numero componenti nucleo familiare*</label>
                                <select id="nucleocomponenti" v-model="form.nucleocomponenti">
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                </select>
                            </div>

                            <div class="form-control">
                                <label for="nucleofamiliariacarico">Familiari a carico*</label>
                                <select id="nucleofamiliariacarico" v-model="form.nucleofamiliariacarico">
                                    <option value="0">0</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                </select>
                            </div>

                            <button class="btn-2 btn-next-step" @click.stop.prevent="nextStep(2)">prosegui</button>
                        </div>
                    </dd>

                    <!-- Residenza -->
                    <dt @click.stop.prevent="toggleForm($event)" id="step_form-3">
                        <button>Residenza</button>
                        <div class="accordion-step">
                            <span>3/9</span>
                            <span class="modify-form-step">modifica</span>
                        </div>
                    </dt>
                    <dd>
                        <div class="ct-input">

                            <div class="form-control">
                                <select id="tipoabitazione" v-model="form.tipoabitazione">
                                    <option disabled value="">Tipo di abitazione*</option>
                                    <option v-for="option in form.options_tipoabitazione" :value="option.key" :key="option.key">
                                        {{ option.value }}
                                    </option>
                                </select>
                                <p class="error" v-show="error.tipoabitazione">{{ message.camporichiesto }}</p>
                            </div>

                            <div class="form-control">
                                <label for="vivoquidaanni">Vivo qui da (anni)*</label>
                                <input id="vivoquidaanni" v-model="form.vivoquidaanni" type="text" placeholder="" @keypress="isNumber($event)" />
                                <p class="error" v-show="error.vivoquidaanni">{{ message.camporichiesto }}</p>
                                <p class="info-input">Indicare 0 se inferiore a un anno</p>
                            </div>

                            <div class="form-control">
                                <label for="indirizzo">Indirizzo*</label>
                                <input id="indirizzo" v-model="form.indirizzo" type="text" placeholder="" />
                                <p class="error" v-show="error.indirizzo">{{ message.camporichiesto }}</p>
                            </div>

                            <div class="form-control">
                                <label for="ncivico">N. Civico*</label>
                                <input id="ncivico" v-model="form.ncivico" type="text" placeholder="" />
                                <p class="error" v-show="error.ncivico">{{ message.camporichiesto }}</p>
                            </div>

                            <div class="form-control">
                                <select id="provincia" v-model="form.provincia">
                                    <template v-if="form.paeseresidenza == 'IT'">
                                        <option disabled value="">Provincia*</option>
                                    </template>
                                    <template v-else>
                                        <option disabled value="">Provincia</option>
                                    </template>
                                    <option v-for="option in form.options_provincia" :value="option.key" :key="option.key">
                                        {{ option.value }}
                                    </option>
                                </select>
                                <p class="error" v-if="form.paeseresidenza == 'IT'" v-show="error.provincia">{{ message.camporichiesto }}</p>
                            </div>

                            <div class="form-control">
                                <label for="codicepostale">Codice postale*</label>
                                <input id="codicepostale" v-model="form.codicepostale" type="text" placeholder="" @keypress="isNumber($event)" />
                                <p class="error" v-show="error.codicepostale">{{ message.camporichiesto }}</p>
                            </div>

                            <div class="form-control">
                                <label for="localita">Località*</label>
                                <input id="localita" v-model="form.localita" type="text" placeholder="" />
                                <p class="error" v-show="error.localita">{{ message.camporichiesto }}</p>
                            </div>

                            <div class="form-control">
                                <select id="paeseresidenza" v-model="form.paeseresidenza">
                                    <option disabled value="">Paese di residenza*</option>
                                    <option v-for="option in form.options_paese" :value="option.code" :key="option.code">
                                        {{ option.name }}
                                    </option>
                                </select>
                                <p class="error" v-show="error.paeseresidenza">{{ message.camporichiesto }}</p>
                            </div>

                            <div class="form-control">
                                <p class="info-input-title">Se la tua residenza non corrisponde al tuo domicilio, spunta la casella.</p>
                                <div class="ct-checkradio">
                                    <input id="checkdomiciliofiscale" v-model="form.checkdomiciliofiscale" type="checkbox" />
                                    <label for="checkdomiciliofiscale" class="label-checkbox">Confermo che la mia attuale residenza non corrisponde al mio domicilio.</label>
                                </div>
                            </div>

                            <!-- Se residenza diversa da domicilio -->
                            <div class="hidden-block" v-if="form.checkdomiciliofiscale">

                                <div class="form-control">
                                    <select id="tipoabitazione2" v-model="form.tipoabitazione2">
                                        <option disabled value="">Tipo di abitazione*</option>
                                        <option v-for="option in form.options_tipoabitazione" :value="option.key" :key="option.key">
                                            {{ option.value }}
                                        </option>
                                    </select>
                                    <p class="error" v-show="error.tipoabitazione2">{{ message.camporichiesto }}</p>
                                </div>

                                <div class="form-control">
                                    <label for="vivoquidaanni2">Vivo qui da (anni)*</label>
                                    <input id="vivoquidaanni2" v-model="form.vivoquidaanni2" type="text" placeholder="" @keypress="isNumber($event)" />
                                    <p class="error" v-show="error.vivoquidaanni2">{{ message.camporichiesto }}</p>
                                    <p class="info-input">Indicare 0 se inferiore a un anno</p>
                                </div>

                                <div class="form-control">
                                    <label for="indirizzo2">Indirizzo*</label>
                                    <input id="indirizzo2" v-model="form.indirizzo2" type="text" placeholder="" />
                                    <p class="error" v-show="error.indirizzo2">{{ message.camporichiesto }}</p>
                                </div>

                                <div class="form-control">
                                    <label for="ncivico2">N. Civico*</label>
                                    <input id="ncivico2" v-model="form.ncivico2" type="text" placeholder="" />
                                    <p class="error" v-show="error.ncivico2">{{ message.camporichiesto }}</p>
                                </div>

                                <div class="form-control">
                                    <select id="provincia2" v-model="form.provincia2">
                                        <template v-if="form.paeseresidenza2 == 'IT'">
                                            <option disabled value="">Provincia*</option>
                                        </template>
                                        <template v-else>
                                            <option disabled value="">Provincia</option>
                                        </template>
                                        <option v-for="option in form.options_provincia" :value="option.key" :key="option.key">
                                            {{ option.value }}
                                        </option>
                                    </select>
                                    <p class="error" v-if="form.paeseresidenza2 == 'IT'" v-show="error.provincia2">{{ message.camporichiesto }}</p>
                                </div>

                                <div class="form-control">
                                    <label for="codicepostale2">Codice postale*</label>
                                    <input id="codicepostale2" v-model="form.codicepostale2" type="text" placeholder="" @keypress="isNumber($event)" />
                                    <p class="error" v-show="error.codicepostale2">{{ message.camporichiesto }}</p>
                                </div>

                                <div class="form-control">
                                    <label for="localita2">Località*</label>
                                    <input id="localita2" v-model="form.localita2" type="text" placeholder="" />
                                    <p class="error" v-show="error.localita2">{{ message.camporichiesto }}</p>
                                </div>

                                <div class="form-control">
                                    <select id="paeseresidenza2" v-model="form.paeseresidenza2">
                                        <option disabled value="">Paese di residenza*</option>
                                        <option v-for="option in form.options_paese" :value="option.code" :key="option.code">
                                            {{ option.name }}
                                        </option>
                                    </select>
                                    <p class="error" v-show="error.paeseresidenza2">{{ message.camporichiesto }}</p>
                                </div>

                            </div>

                            <button class="btn-2 btn-next-step" @click.stop.prevent="nextStep(3)">prosegui</button>
                        </div>
                    </dd>

                    <!-- Documento d’identità -->
                    <dt @click.stop.prevent="toggleForm($event)" id="step_form-4">
                        <button>Documento d’identità</button>
                        <div class="accordion-step">
                            <span>4/9</span>
                            <span class="modify-form-step">modifica</span>
                        </div>
                    </dt>
                    <dd>
                        <div class="ct-input">

                            <div class="form-control">
                                <select id="tipodidocumento" v-model="form.tipodidocumento">
                                    <option v-for="option in form.options_tipodidocumento" :value="option.key" :key="option.key">
                                        {{ option.value }}
                                    </option>
                                </select>
                                <p class="error" v-show="error.tipodidocumento">{{ message.camporichiesto }}</p>
                            </div>

                            <div class="form-control">
                                <label for="numerodocumento">Numero della patente di guida *</label>
                                <input id="numerodocumento" v-model="form.numerodocumento" type="text" placeholder="" />
                                <p class="error" v-show="error.numerodocumento">{{ message.camporichiesto }}</p>
                            </div>

                            <div class="form-control">
                                <label for="datadirilascio">Data di emissione *</label>
                                <input id="datadirilascio" v-model="form.datadirilascio" type="date" placeholder="gg/mm/aaaa" />
                                <p class="error" v-show="error.datadirilascio">{{ message.camporichiesto }}</p>
                            </div>

                            <div class="form-control">
                                <select id="paesedirilascio" v-model="form.paesedirilascio">
                                    <option disabled value="">Paese di emissione*</option>
                                    <option v-for="option in form.options_paese" :value="option.code" :key="option.code">
                                        {{ option.name }}
                                    </option>
                                </select>
                                <p class="error" v-show="error.paesedirilascio">{{ message.camporichiesto }}</p>
                            </div>

                            <div class="form-control">
                                <label for="rilasciatoda">Rilasciato da*</label>
                                <select id="rilasciatoda" v-model="form.rilasciatoda">
                                    <option v-for="option in form.options_documentorilasciatoda" :value="option.key" :key="option.key">
                                        {{ option.value }}
                                    </option>
                                </select>
                                <p class="error" v-show="error.rilasciatoda">{{ message.camporichiesto }}</p>
                            </div>

                            <div class="form-control">
                                <label for="luogodirilascio">Luogo di rilascio*</label>
                                <input id="luogodirilascio" v-model="form.luogodirilascio" type="text" placeholder="" />
                                <p class="error" v-show="error.luogodirilascio">{{ message.camporichiesto }}</p>
                            </div>

                            <div class="form-control">
                                <label for="datadiscadenza">Data di scadenza*</label>
                                <input id="datadiscadenza" v-model="form.datadiscadenza" type="date" placeholder="gg/mm/aaaa" />
                                <p class="error" v-show="error.datadiscadenza">{{ message.camporichiesto }}</p>
                            </div>

                            <button class="btn-2 btn-next-step" @click.stop.prevent="nextStep(4)">prosegui</button>
                        </div>
                    </dd>

                    <!-- Informazioni professionali -->
                    <dt @click.stop.prevent="toggleForm($event)" id="step_form-5">
                        <button>Informazioni professionali</button>
                        <div class="accordion-step">
                            <span>5/9</span>
                            <span class="modify-form-step">modifica</span>
                        </div>
                    </dt>
                    <dd>
                        <div class="ct-input">

                            <!-- nascosto e fisso su "LAV.DIPENDENTE" -->
                            <div class="form-control hidden">
                                <label for="infoprofessione">Professione*</label>
                                <select id="infoprofessione" v-model="form.infoprofessione">
                                    <option v-for="option in form.options_infoprofessione" :value="option.key" :key="option.key">
                                        {{ option.value }}
                                    </option>
                                </select>
                                <p class="error" v-show="error.infoprofessione">{{ message.camporichiesto }}</p>
                            </div>

                            <!-- questa select fa compilare in automatico gli altri input -->
                            <div class="form-control">
                                <select id="infodatoredilavoro" v-model="form.infodatoredilavoro" @change="employerChange($event)">
                                    <option disabled value="">Datore di lavoro*</option>
                                    <option value="rci">RCI BANQUE SA</option>
                                    <option value="renault">Renault Italia S.p.A.</option>
                                    <option value="sodicam">SODICAM</option>
                                    <option value="rrg">RRG</option>
                                </select>
                                <p class="error" v-show="error.infodatoredilavoro">{{ message.camporichiesto }}</p>
                            </div>

                            <div class="form-control">
                                <label for="infotelefonofisso">Telefono</label>
                                <input id="infotelefonofisso" v-model="form.infotelefonofisso" type="tel" placeholder="" disabled />
                            </div>

                            <div class="form-control">
                                <label for="infoindirizzo">Indirizzo*</label>
                                <input id="infoindirizzo" v-model="form.infoindirizzo" type="text" placeholder="" disabled />
                                <p class="error" v-show="error.infoindirizzo">{{ message.camporichiesto }}</p>
                            </div>

                            <div class="form-control">
                                <label for="infonumerocivico">Numero civico*</label>
                                <input id="infonumerocivico" v-model="form.infonumerocivico" type="text" placeholder="" disabled />
                                <p class="error" v-show="error.infonumerocivico">{{ message.camporichiesto }}</p>
                            </div>

                            <div class="form-control">
                                <select id="infoprovincia" v-model="form.infoprovincia" disabled>
                                    <template v-if="form.infopaese == 'IT'">
                                        <option disabled value="">Provincia*</option>
                                    </template>
                                    <template v-else>
                                        <option disabled value="">Provincia</option>
                                    </template>
                                    <option v-for="option in form.options_provincia" :value="option.key" :key="option.key">
                                        {{ option.value }}
                                    </option>
                                </select>
                                <p class="error" v-if="form.infopaese == 'IT'" v-show="error.infoprovincia">{{ message.camporichiesto }}</p>
                            </div>

                            <div class="form-control">
                                <select id="infocodicepostale" v-model="form.infocodicepostale" disabled>
                                    <option disabled value="">Codice postale*</option>
                                    <option v-for="option in form.options_infocodicepostale" :value="option.key" :key="option.key">
                                        {{ option.value }}
                                    </option>
                                </select>
                                <p class="error" v-show="error.infocodicepostale">{{ message.camporichiesto }}</p>
                            </div>

                            <div class="form-control">
                                <select id="infolocalita" v-model="form.infolocalita" disabled>
                                    <option disabled value="">Località*</option>
                                    <option v-for="option in form.options_infolocalita" :value="option.key" :key="option.key">
                                        {{ option.value }}
                                    </option>
                                </select>
                                <p class="error" v-show="error.infolocalita">{{ message.camporichiesto }}</p>
                            </div>

                            <div class="form-control">
                                <select id="infopaese" v-model="form.infopaese" disabled>
                                    <option disabled value="">Paese*</option>
                                    <option v-for="option in form.options_paese" :value="option.code" :key="option.code">
                                        {{ option.name }}
                                    </option>
                                </select>
                                <p class="error" v-show="error.infopaese">{{ message.camporichiesto }}</p>
                            </div>

                            <div class="form-control hidden">
                                <label for="inforetribuzione">Retribuzione mensile netta*</label>
                                <input id="inforetribuzione" v-model="form.inforetribuzione" type="hidden" value="999" />
                            </div>

                            <div class="form-control">
                                <label for="infodatadiassunzione">
                                    Data di assunzione*  “Come indicato su busta paga”
                                    <button class="btn-data-assunzione" @click.stop.prevent="openPopupDataAssunzione">
                                        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="6.5" cy="6.5" r="6" stroke="black" />
                                            <rect x="6" y="3" width="1" height="1" fill="black" />
                                            <rect x="6" y="5" width="1" height="5" fill="black" /></svg>
                                    </button>
                                </label>
                                <input id="infodatadiassunzione" v-model="form.infodatadiassunzione" type="date" placeholder="gg/mm/aaaa" />
                                <p class="error" v-show="error.infodatadiassunzione">{{ message.camporichiesto }}</p>
                            </div>

                            <button class="btn-2 btn-next-step" @click.stop.prevent="nextStep(5)">prosegui</button>
                        </div>
                    </dd>

                    <!-- Dati bancari -->
                    <dt @click.stop.prevent="toggleForm($event)" id="step_form-6">
                        <button>Dati bancari</button>
                        <div class="accordion-step">
                            <span>6/9</span>
                            <span class="modify-form-step">modifica</span>
                        </div>
                    </dt>
                    <dd>
                        <div class="ct-input">

                            <p class="info-input2">Il conto corrente deve essere intestato alla persona che richiede il noleggio dell’auto e <span>deve essere lo stesso utilizzato per l’accredito dello stipendio.</span></p>

                            <div class="form-control">
                                <label for="codiceiban">Codice IBAN*</label>
                                <input id="codiceiban" v-model="form.codiceiban" type="text" placeholder="" />
                                <p class="error" v-show="error.codiceiban">{{ message.camporichiesto }}</p>
                                <p class="error" v-show="error.codiceibanvalido">{{ error.codiceiban_msg }}</p>
                            </div>

                            <div class="form-control">
                                <label for="titolarecontocorrente">Nome*</label>
                                <input id="titolarecontocorrente" v-model="form.titolarecontocorrente" type="text" placeholder="" />
                                <p class="error" v-show="error.titolarecontocorrente">{{ message.camporichiesto }}</p>
                            </div>

                            <!-- campi nascosti autocompilati al submit del form in base alla residenza (1) -->
                            <div class="form-control hidden">
                                <label for="datiBancari_indirizzo">Indirizzo</label>
                                <input id="datiBancari_indirizzo" v-model="form.datiBancari_indirizzo" type="text" placeholder="" />
                            </div>

                            <div class="form-control hidden">
                                <label for="datiBancari_codicepostale">Codice postale</label>
                                <input id="datiBancari_codicepostale" v-model="form.datiBancari_codicepostale" type="text" placeholder="" />
                            </div>

                            <div class="form-control hidden">
                                <label for="datiBancari_localita">Località</label>
                                <input id="datiBancari_localita" v-model="form.datiBancari_localita" type="text" placeholder="" />
                            </div>

                            <div class="form-control hidden">
                                <select id="datiBancari_provincia" v-model="form.datiBancari_provincia">
                                    <option disabled value="">Provincia</option>
                                    <option v-for="option in form.options_provincia" :value="option.key" :key="option.key">
                                        {{ option.value }}
                                    </option>
                                </select>
                            </div>

                            <button class="btn-2 btn-next-step" @click.stop.prevent="nextStep(6)">prosegui</button>
                        </div>
                    </dd>

                    <!-- Assicurazione e consegna -->
                    <dt @click.stop.prevent="toggleForm($event)" id="step_form-7">
                        <button>Assicurazione e consegna</button>
                        <div class="accordion-step">
                            <span>7/9</span>
                            <span class="modify-form-step">modifica</span>
                        </div>
                    </dt>
                    <dd>
                        <div class="ct-input">

                            <div class="form-control">
                                <label for="targavettura">Targa vettura</label>
                                <input id="targavettura" v-model="form.targavettura" type="text" placeholder="" />
                                <p class="info-input">Inserendo la targa di una tua auto già assicurata puoi conservare la classe di merito anche per il futuro (campo opzionale)</p>
                            </div>

                            <div class="form-control">
                                <select id="filialeritiro" v-model="form.filialeritiro">
                                    <option disabled value="">Filiale per il ritiro *</option>
                                    <option value="RM">Roma</option>
                                    <option value="MI">Milano</option>
                                </select>
                                <p class="error" v-show="error.filialeritiro">{{ message.camporichiesto }}</p>
                            </div>

                            <button class="btn-2 btn-next-step" @click.stop.prevent="nextStep(7)">prosegui</button>
                        </div>
                    </dd>

                    <!-- Trattamento dati personali -->
                    <dt @click.stop.prevent="toggleForm($event)" id="step_form-8">
                        <button>Trattamento dati personali</button>
                        <div class="accordion-step">
                            <span>8/9</span>
                            <span class="modify-form-step">modifica</span>
                        </div>
                    </dt>
                    <dd>
                        <div class="ct-input">

                            <p class="title-big">INFORMAZIONI AI SENSI DEL REGOLAMENTO UE N.679/2016</p>

                            <div class="box-gray">
                                <div class="form-control">
                                    <p class="title-big">Consenso necessario al trattamento dei dati personali</p>
                                    <p class="text-privacy">Dichiaro di essere stato preventivamente informato in ordine alle disposizioni della normativa sulla tutela delle persone e di altri soggetti rispetto al trattamento dei dati personali ai sensi degli artt. 13 e 14 del Regolamento UE 679/2016 e di aver ricevuto la relativa informativa sul trattamento dei dati personali da parte di ES Mobility S.r.l. (Sezione 7 delle Condizione Generali di Locazione).</p>
                                    <a class="link" target="_blank" href="http://www.renaultlease.it/download/renault_lease_informativa_sul_trattamento_dei_dati_personali_locazione_veicolo.pdf">Informativa sul trattamento dei dati personali - Locazione veicolo</a>
                                    <div class="ct-checkradio">
                                        <input id="checktrattamentodati" v-model="form.checktrattamentodati" type="checkbox" />
                                        <label for="checktrattamentodati" class="label-checkbox">Ho letto e accetto i termini e le condizioni</label>
                                    </div>
                                    <p class="error" v-show="error.checktrattamentodati">{{ message.camporichiesto }}</p>
                                </div>
                            </div>

                            <p class="title-big">Preso atto dell’informativa e consapevole della facoltà di revoca:</p>

                            <div class="box-gray">

                                <div class="form-control">
                                    <p class="text-privacy">Acconsento espressamente e specificatamente alla comunicazione e all’utilizzo dei miei dati da parte di società terze, della Rete Convenzionata, nonché degli altri soggetti menzionati nell’informativa per finalità di marketing, promozionale e commerciale, relative a prodotti o servizi propri o di società terze, ovvero i prodotti e i servizi di RCI Banque S.A. o di altre società del Gruppo o della cd. Alleanza (as esempio: Renault Italia S.p.A., Nissan Italia S.r.l.), indicate al pargrafo 2, punto VIII dell’informativa, con le modalità ivi previste.</p>
                                    <div class="ct-checkradio2">
                                        <div class="ct-checkradio">
                                            <input id="radiocommercialivsterzi_1" v-model="form.radiocommercialivsterzi" type="radio" value="accetto" />
                                            <label for="radiocommercialivsterzi_1" class="label-checkbox">Accetto</label>
                                        </div>
                                        <div class="ct-checkradio">
                                            <input id="radiocommercialivsterzi_2" v-model="form.radiocommercialivsterzi" type="radio" value="non accetto" />
                                            <label for="radiocommercialivsterzi_2" class="label-checkbox">Non accetto</label>
                                        </div>
                                    </div>
                                    <p class="error" v-show="error.radiocommercialivsterzi">{{ message.camporichiesto }}</p>
                                </div>

                                <div class="form-control form-control2">
                                    <p class="text-privacy">Acconsento espressamente e specificatamente al trattamento dei miei dati personali da parte di ES Mobility S.r.l. e dei terzi ai quali quest’ultima abbia comunicato i suddetti dati per iniziative marketing, promozionali e commerciali, ricerche di mercato o di soddisfazione della clientela, indicate al paragrafo 2, punto VIII dell’informativa, con le modalità ivi previste.</p>
                                    <div class="ct-checkradio2">
                                        <div class="ct-checkradio">
                                            <input id="radiocommercialivses_1" v-model="form.radiocommercialivses" type="radio" value="accetto" />
                                            <label for="radiocommercialivses_1" class="label-checkbox">Accetto</label>
                                        </div>
                                        <div class="ct-checkradio">
                                            <input id="radiocommercialivses_2" v-model="form.radiocommercialivses" type="radio" value="non accetto" />
                                            <label for="radiocommercialivses_2" class="label-checkbox">Non accetto</label>
                                        </div>
                                    </div>
                                    <p class="error" v-show="error.radiocommercialivses">{{ message.camporichiesto }}</p>
                                </div>
                            </div>

                            <button class="btn-2 btn-next-step" @click.stop.prevent="nextStep(8)">prosegui</button>
                        </div>
                    </dd>

                    <!-- Condizioni generali di locazione  a lungo termine veicoli -->
                    <dt @click.stop.prevent="toggleForm($event)" id="step_form-9">
                        <button>Condizioni generali di locazione a lungo termine veicoli</button>
                        <div class="accordion-step">
                            <span>9/9</span>
                            <span class="modify-form-step">modifica</span>
                        </div>
                    </dt>
                    <dd>
                        <div class="ct-input">

                            <p class="title-big">INFORMAZIONI PRECONTRATTUALI Al SENSI DELL'ART. 49 DEL CODICE DEL CONSUMO - D.LGS. N. 206/2005</p>
                            <p class="text-privacy">Il sottoscritto dichiara di aver ricevuto informariva precontrattuale resa ai sensi del D. Lgs. 200/2005 - Codice del Consumo e di essere debitamente informato sul diritto di recedere dal contratto entro 14 giorni dalla conclusione dello stesso, ai sensi dell'art. 37 delle Condizioni Generali di Locazione.</p>

                            <div class="box-gray">

                                <div class="form-control">
                                    <p class="title-big">Condizioni generali di locazione</p>
                                    <p class="text-privacy">Il presente contratto è disciplinato dalle Condizioni Generali di Locazione che dichiaro di aver ricevuto e comunque di ben conoscere ed accettare integralmente quale parte integrante ed essenziale del rapporto di locazione.</p>
                                    <a class="link" target="_blank" href="http://www.renaultlease.it/download/renault_lease_informativa_precontrattuale_ex_art.49_codice_al_consumo.pdf">Informativa precontrattuale ex art..49 Codice al consumo</a>
                                    <a class="link" target="_blank" href="http://www.renaultlease.it/download/renault_lease_condizioni_generali_di_noleggio_lungo_periodo.pdf">Condizioni generali di locazione a lungo termine di veicoli</a>

                                    <div class="ct-checkradio">
                                        <input id="checkcondizionigenerali" v-model="form.checkcondizionigenerali" type="checkbox" />
                                        <label for="checkcondizionigenerali" class="label-checkbox">Ho letto e accetto i termini e le condizioni</label>
                                    </div>
                                    <p class="error" v-show="error.checkcondizionigenerali">{{ message.camporichiesto }}</p>
                                </div>

                                <div class="form-control form-control2">
                                    <p class="title-big">Cessione del credito</p>
                                    <p class="text-privacy">Preso atto della Facoltà del Locatore di cedere i crediti derivanti dal presente contratto di locazione, ai sensi del'art.16, sezione 1, delle Condizioni generali di locazione, il sottoscritto Locatario accetta incondizionatomente che il Locatore ES Mobility Sr.l. ceda tutti i crediti nei confronti del Locatario derivanti dal presente contratto a RCI Banque S.A. Succursale italiana, Via Tiburtina 1159, 00159 Roma, CF/Part. IVA 0687474100. In relazione alla sudderca cessione dl credito dichiara di essere stato/a preventivamente informoto/a in ordine alle disposizioni delle normativa sulla tutela delle persone e di altri soggetti rispetto al trattamento dei dati personali ai sensi degli artt. 13 e 14 del Regolomento UE UE n.679 del 2016 e di aver ricevuta lo relativa informativa di RCI Banque S.A. Succursale italiana relativa ol trattamento de dati da parte della stessa (Mod. Info. Cessione Crediti), nonché il modello di informativa relativo al "Codice di condotta per i sistemi informativi gestiti da soggetti privati in tema di crediti al consumo, affidabilità e puntualità nei pagamenti” (Mod. Info SIC).</p>
                                    <a class="link" target="_blank" href="http://www.renaultlease.it/download/renault_lease_informativa_sul_trattamento_dei_dati_personali_cessione_crediti.pdf">Informativa sul trattamento dei dati personali - Cessione dei crediti</a>

                                    <div class="ct-checkradio">
                                        <input id="checkcessionedelcredito" v-model="form.checkcessionedelcredito" type="checkbox" />
                                        <label for="checkcessionedelcredito" class="label-checkbox">Ho letto e accetto i termini e le condizioni</label>
                                    </div>
                                    <p class="error" v-show="error.checkcessionedelcredito">{{ message.camporichiesto }}</p>
                                </div>

                                <div class="form-control form-control2">
                                    <p class="title-big">Regolamento</p>

                                    <div class="ct-checkradio">
                                        <input id="checkregolamento" v-model="form.checkregolamento" type="checkbox" />
                                        <label for="checkregolamento" class="label-checkbox">Ho letto e accetto i <a href="#">termini e le condizioni del regolamento del noleggio dipendenti</a></label>
                                    </div>
                                    <p class="error" v-show="error.checkregolamento">{{ message.camporichiesto }}</p>
                                </div>
                            </div>

                            <p class="form-message-errors" v-if="formInvalidMessage">La sua richiesta non può essere inviata perché alcuni campi non sono stati compilati correttamente. Controllare le risposte prima di inviare nuovamente la richiesta.</p>
                            <p class="form-message-errors" v-if="formInvalidMessageAjax">{{ message.controlloAjax }}</p>

                            <input class="btn-1 send-request" type="submit" :disabled="disableSubmit" value="invia la richiesta" />
                        </div>
                    </dd>

                </dl>

            </form>

        </div>
    </div>

    <div class="popup-assunzione" v-if="!popupIsHidden">
        <button class="close-assunzione" @click.stop.prevent="closePopupDataAssunzione()">
            <svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line x1="29.6377" y1="0.353553" x2="1.35346" y2="28.6378" stroke="white" />
                <line x1="28.9306" y1="28.6377" x2="0.646358" y2="0.35346" stroke="white" />
            </svg>
        </button>
        <div class="inner-assunzione">
            <img src="@/assets/img/assunzione.jpg" />
            <p>Puoi trovare la data di assunzione all’interno della busta paga, nella zona evidenziata in rosso</p>
        </div>
    </div>

    <div class="loader" v-show="showLoader"></div>

    <Footer />

</div>
</template>

<script>
// @ is an alias to /src
import $ from 'jquery'

import CarInfo from '@/components/CarInfo.vue'
import Footer from '@/components/Footer.vue'

import json_province from '@/json/GET_provinces.json'
import json_tipoabitazione from '@/json/GET_address_types.json'
import json_citta from '@/json/GET_citeis.json'
import json_professione from '@/json/GET_employment_groups.json'
import json_statocivile from '@/json/GET_marital_status.json'
import json_codicepostale from '@/json/GET_postal_codes.json'
import json_tipodidocumento from '@/json/GET_identity_document_types.json'
import json_documentorilasciatoda from '@/json/GET_identity_document_issuers.json'
import json_paese from '@/json/country.json' //my

import delete_prenotazione from '@/mixins/requests/delete_prenotazione'
import post_prospects from '@/mixins/requests/post_prospects'
import post_proposal from '@/mixins/requests/post_proposal'
import patch_prospects from '@/mixins/requests/patch_prospects'
import patch_proposal from '@/mixins/requests/patch_proposal'
import get_proposal_status from '@/mixins/requests/get_proposal_status'

export default {
    name: 'Form',

    mixins: [delete_prenotazione, post_prospects, post_proposal, patch_prospects, patch_proposal, get_proposal_status],

    components: {
        CarInfo,
        Footer
    },

    mounted() {

        //redirect in home se refresh pagina
        if (this.$store.state.car.info.length == 0) {
            window.location.replace('/');
        }

        //imposta la data selezionabile massima o minima a quella odierna
        document.getElementById("datadinascita").max = new Date().toISOString().split("T")[0];
        document.getElementById("datadirilascio").max = new Date().toISOString().split("T")[0];
        document.getElementById("datadiscadenza").min = new Date().toISOString().split("T")[0];
        document.getElementById("infodatadiassunzione").max = new Date().toISOString().split("T")[0];
    },

    data: function () {
        return {
            errors: [],
            message: {
                camporichiesto: 'Campo richiesto',
                controlloAjax: ''
            },
            error: {
                //Informazioni personali
                ipn: false,
                ipnvalido: false,
                ipn_msg: 'Il codice inserito deve essere alfanumerico e di 7 caratteri',
                email: false,
                emailnonvalida: false,
                confermaemail: false,
                confermaemailnonvalida: false,
                emailcorrispondenti: false,
                emailnonvalida_msg: 'Email non valida',
                email_msg: 'Email non corrispondenti',
                statocivile: false,
                cognome: false,
                nome: false,
                datadinascita: false,
                codicefiscale: false,
                codicefiscalevalido: false,
                codicefiscale_msg: 'Il codice inserito deve essere lungo esattamente 16 caratteri',
                telefono: false,
                //Nucleo familiare
                nucleostatocivile: false,
                //Residenza
                tipoabitazione: false,
                vivoquidaanni: false,
                indirizzo: false,
                ncivico: false,
                provincia: false,
                codicepostale: false,
                localita: false,
                paeseresidenza: false,
                //Residenza 2 (domicilio)
                tipoabitazione2: false,
                vivoquidaanni2: false,
                indirizzo2: false,
                ncivico2: false,
                provincia2: false,
                codicepostale2: false,
                localita2: false,
                paeseresidenza2: false,
                //Informazioni professionali
                infoprofessione: false,
                infodatoredilavoro: false,
                infoindirizzo: false,
                infonumerocivico: false,
                infoprovincia: false,
                infocodicepostale: false,
                infolocalita: false,
                infopaese: false,
                infodatadiassunzione: false,
                //Dati bancari
                titolarecontocorrente: false,
                codiceiban: false,
                codiceibanvalido: false,
                codiceiban_msg: 'Il codice inserito deve avere una lunghezza compresa tra 16 e 34 caratteri',
                //Assicurazione e consegna
                filialeritiro: false,
                //Trattamento dati personali
                checktrattamentodati: false,
                radiocommercialivses: false,
                radiocommercialivsterzi: false,
                checkcondizionigenerali: false,
                checkcessionedelcredito: false,
                checkregolamento: false,
                //Trattamento dati personali
                tipodidocumento: false,
                numerodocumento: false,
                datadirilascio: false,
                rilasciatoda: false,
                paesedirilascio: false,
                luogodirilascio: false,
                datadiscadenza: false,
            },
            form: {
                //Informazioni personali
                statocivile: '',
                ipn: '',
                email: '',
                confermaemail: '',
                cognome: '',
                nome: '',
                datadinascita: '',
                codicefiscale: '',
                telefono: '',
                //Nucleo familiare
                nucleostatocivile: '',
                nucleocomponenti: 1,
                nucleofamiliariacarico: 0,
                //Residenza
                tipoabitazione: '',
                vivoquidaanni: '',
                indirizzo: '',
                ncivico: '',
                provincia: '',
                codicepostale: '',
                localita: '',
                paeseresidenza: '',
                checkdomiciliofiscale: false,
                //Residenza 2 (domicilio)
                tipoabitazione2: '',
                vivoquidaanni2: '',
                indirizzo2: '',
                ncivico2: '',
                provincia2: '',
                codicepostale2: '',
                localita2: '',
                paeseresidenza2: '',
                //Documento d’identità
                tipodidocumento: 'DRIVING_LICENSE',
                numerodocumento: '',
                datadirilascio: '',
                rilasciatoda: 'MOTOR_VEHICLE_DPT',
                paesedirilascio: '',
                luogodirilascio: '',
                datadiscadenza: '',
                //Informazioni professionali
                infoprofessione: 'LD',
                infodatoredilavoro: '',
                infotelefonofisso: '',
                infoindirizzo: '',
                infonumerocivico: '',
                infoprovincia: '',
                infocodicepostale: '',
                infolocalita: '',
                infopaese: '',
                inforetribuzione: 999,
                infodatadiassunzione: '',
                //Dati bancari
                titolarecontocorrente: '',
                codiceiban: '',
                //Assicurazione e consegna
                targavettura: '',
                filialeritiro: '',
                //Trattamento dati personali
                checktrattamentodati: false,
                radiocommercialivses: 'non accetto',
                radiocommercialivsterzi: 'non accetto',
                checkcondizionigenerali: false,
                checkcessionedelcredito: false,
                checkregolamento: false,

                //Popola select da json
                options_nucleostatocivile: json_statocivile,
                options_tipoabitazione: json_tipoabitazione,
                options_provincia: json_province,
                options_infoprofessione: json_professione,
                options_infocodicepostale: json_codicepostale,
                options_infolocalita: json_citta,
                options_paese: json_paese,
                options_tipodidocumento: json_tipodidocumento,
                options_documentorilasciatoda: json_documentorilasciatoda

                //Informazioni personali        //TODO - eliminare e decommentare quella sopra
                // statocivile: 'MALE',
                // ipn: 'fdsfsd2',
                // email: 'ab@test.it',
                // confermaemail: 'ab@test.it',
                // cognome: 'asdsa',
                // nome: 'asas',
                // datadinascita: '2022-10-19',
                // codicefiscale: 'brnndr89d10l407s',
                // telefono: '12321',
                // //Nucleo familiare
                // nucleostatocivile: 'C',
                // nucleocomponenti: 1,
                // nucleofamiliariacarico: 0,
                // //Residenza
                // tipoabitazione: 'P',
                // vivoquidaanni: '15',
                // indirizzo: 'vavasv',
                // ncivico: '3',
                // provincia: 'TV',
                // codicepostale: '32112',
                // localita: 'fasfa',
                // paeseresidenza: 'IT',
                // checkdomiciliofiscale: false,
                // //Residenza 2 (domicilio)
                // tipoabitazione2: '',
                // vivoquidaanni2: '',
                // indirizzo2: '',
                // ncivico2: '',
                // provincia2: '',
                // codicepostale2: '',
                // localita2: '',
                // paeseresidenza2: '',
                // //Documento d’identità
                // tipodidocumento: 'DRIVING_LICENSE',
                // numerodocumento: 'bdfbdbsdb',
                // datadirilascio: '2022-10-19',
                // rilasciatoda: 'MOTOR_VEHICLE_DPT',
                // paesedirilascio: 'IT',
                // luogodirilascio: 'asca',
                // datadiscadenza: '2024-10-23',
                // //Informazioni professionali
                // infoprofessione: 'LD',
                // infodatoredilavoro: 'rci',
                // infotelefonofisso: '12312',
                // infoindirizzo: 'vsdvsd',
                // infonumerocivico: '22',
                // infoprovincia: 'TV',
                // infocodicepostale: '3213',
                // infolocalita: 'avasvsa',
                // infopaese: 'IT',
                // inforetribuzione: 999,
                // infodatadiassunzione: '2022-10-19',
                // //Dati bancari
                // titolarecontocorrente: 'ascaas',
                // codiceiban: '32423452332234324',
                // //Assicurazione e consegna
                // targavettura: '',
                // filialeritiro: 'RM',
                // //Trattamento dati personali
                // checktrattamentodati: true,
                // radiocommercialivses: 'accetto',
                // radiocommercialivsterzi: 'accetto',
                // checkcondizionigenerali: true,
                // checkcessionedelcredito: true,
                // checkregolamento: true,

                // //Popola select da json
                // options_nucleostatocivile: json_statocivile,
                // options_tipoabitazione: json_tipoabitazione,
                // options_provincia: json_province,
                // options_infoprofessione: json_professione,
                // options_infocodicepostale: json_codicepostale,
                // options_infolocalita: json_citta,
                // options_paese: json_paese,
                // options_tipodidocumento: json_tipodidocumento,
                // options_documentorilasciatoda: json_documentorilasciatoda
            },
            popupIsHidden: true,
            formIsValid: false,
            formInvalidMessage: false,
            formInvalidMessageAjax: false,
            showLoader: false,
            disableSubmit: false
        }
    },
    methods: {
        onSubmit: function (e) {
            e.preventDefault();
            this.errors = [];
            //VERIFICA SE CAMPI OBBLIGATORI COMPILATI
            //Informazioni personali
            this.error.ipn = !this.form.ipn;
            this.error.ipnvalido = !this.isAlphanumeric(this.form.ipn);
            this.error.email = !this.form.email;
            this.error.confermaemail = !this.form.confermaemail;
            this.error.emailnonvalida = !this.isEmail(this.form.email);
            this.error.confermaemailnonvalida = !this.isEmail(this.form.confermaemail);
            this.error.emailcorrispondenti = this.form.email != this.form.confermaemail;
            this.error.statocivile = !this.form.statocivile;
            this.error.cognome = !this.form.cognome;
            this.error.nome = !this.form.nome;
            this.error.datadinascita = !this.form.datadinascita;
            this.error.codicefiscale = !this.form.codicefiscale;
            this.error.codicefiscalevalido = !this.isFiscalCode(this.form.codicefiscale);
            this.error.telefono = !this.form.telefono;
            //Nucleo familiare
            this.error.nucleostatocivile = !this.form.nucleostatocivile;
            //Residenza
            this.error.tipoabitazione = !this.form.tipoabitazione;
            this.error.vivoquidaanni = !this.form.vivoquidaanni;
            this.error.indirizzo = !this.form.indirizzo;
            this.error.ncivico = !this.form.ncivico;
            if (this.form.paeseresidenza == 'IT') {
                this.error.provincia = !this.form.provincia;
            }
            this.error.codicepostale = !this.form.codicepostale;
            this.error.localita = !this.form.localita;
            this.error.paeseresidenza = !this.form.paeseresidenza;

            if (this.form.checkdomiciliofiscale) {
                //Residenza 2 (domicilio)
                this.error.tipoabitazione2 = !this.form.tipoabitazione2;
                this.error.vivoquidaanni2 = !this.form.vivoquidaanni2;
                this.error.indirizzo2 = !this.form.indirizzo2;
                this.error.ncivico2 = !this.form.ncivico2;
                if (this.form.paeseresidenza2 == 'IT') {
                    this.error.provincia2 = !this.form.provincia2;
                }
                this.error.codicepostale2 = !this.form.codicepostale2;
                this.error.localita2 = !this.form.localita2;
                this.error.paeseresidenza2 = !this.form.paeseresidenza2;
            }

            //Documento d’identità
            this.error.tipodidocumento = !this.form.tipodidocumento;
            this.error.numerodocumento = !this.form.numerodocumento;
            this.error.datadirilascio = !this.form.datadirilascio;
            this.error.rilasciatoda = !this.form.rilasciatoda;
            this.error.paesedirilascio = !this.form.paesedirilascio;
            this.error.luogodirilascio = !this.form.luogodirilascio;
            this.error.datadiscadenza = !this.form.datadiscadenza;
            //Informazioni professionali
            this.error.infoprofessione = !this.form.infoprofessione;
            this.error.infodatoredilavoro = !this.form.infodatoredilavoro;
            this.error.infoindirizzo = !this.form.infoindirizzo;
            this.error.infonumerocivico = !this.form.infonumerocivico;
            if (this.form.infopaese == 'IT') {
                this.error.infoprovincia = !this.form.infoprovincia;
            }
            this.error.infocodicepostale = !this.form.infocodicepostale;
            this.error.infolocalita = !this.form.infolocalita;
            this.error.infopaese = !this.form.infopaese;
            this.error.infodatadiassunzione = !this.form.infodatadiassunzione;
            //Dati bancari
            this.error.titolarecontocorrente = !this.form.titolarecontocorrente;
            this.error.codiceiban = !this.form.codiceiban;
            //Assicurazione e consegna
            this.error.filialeritiro = !this.form.filialeritiro;
            //Trattamento dati personali
            this.error.codiceibanvalido = !this.isIbanCode(this.form.codiceiban);
            this.error.checktrattamentodati = !this.form.checktrattamentodati;
            this.error.radiocommercialivses = this.form.radiocommercialivses != 'accetto';
            this.error.radiocommercialivsterzi = this.form.radiocommercialivsterzi != 'accetto';
            this.error.checkcondizionigenerali = !this.form.checkcondizionigenerali;
            this.error.checkcessionedelcredito = !this.form.checkcessionedelcredito;
            this.error.checkregolamento = !this.form.checkregolamento;

            //COMPILA QUESTI CAMPI DEI DATI BANCARI IN BASE ALLA RESIDENZA
            this.form.datiBancari_indirizzo = this.form.indirizzo + ' ' + this.form.ncivico;
            this.form.datiBancari_codicepostale = this.form.codicepostale;
            this.form.datiBancari_localita = this.form.localita;
            this.form.datiBancari_provincia = this.form.provincia;

            //
            if (this.error.ipn || this.error.ipnvalido || this.error.email || this.error.emailnonvalida || this.error.confermaemailnonvalida || this.error.confermaemail || this.error.emailcorrispondenti || this.error.statocivile || this.error.nome || this.error.cognome || this.error.datadinascita || this.error.codicefiscale || this.error.codicefiscalevalido || this.error.telefono || this.error.nucleostatocivile || this.error.tipoabitazione || this.error.vivoquidaanni || this.error.indirizzo || this.error.ncivico || this.error.provincia || this.error.codicepostale || this.error.localita || this.error.paeseresidenza || this.error.tipoabitazione2 || this.error.vivoquidaanni2 || this.error.indirizzo2 || this.error.ncivico2 || this.error.provincia2 || this.error.codicepostale2 || this.error.localita2 || this.error.paeseresidenza2 || this.error.infoprofessione || this.error.infodatoredilavoro || this.error.infoindirizzo || this.error.infonumerocivico || this.error.infoprovincia || this.error.infocodicepostale || this.error.infolocalita || this.error.infopaese || this.error.infodatadiassunzione || this.error.titolarecontocorrente || this.error.codiceiban || this.error.filialeritiro || this.error.codiceibanvalido || this.error.checktrattamentodati || this.error.radiocommercialivses || this.error.radiocommercialivsterzi || this.error.checkcondizionigenerali || this.error.checkcessionedelcredito || this.error.checkregolamento || this.error.tipodidocumento || this.error.numerodocumento || this.error.datadirilascio || this.error.rilasciatoda || this.error.paesedirilascio || this.error.luogodirilascio || this.error.datadiscadenza) {
                //controllo campi Residenza 2 (domicilio) se checkbox selezionata
                this.formIsValid = false;
                this.formInvalidMessage = true;
                console.log('errore campi form')
                //return false;
            } else {
                this.formIsValid = true;
                this.formInvalidMessage = false;
                console.log('campi form corretti')
                //return true;
            }

            this.$store.dispatch('setFormFieldsValid', this.formIsValid);

            //se campi validati
            if (this.formIsValid) {

                this.showLoader = true;
                this.disableSubmit = true;

                //imposta i dati nello store - DATI UTENTE CHE VENGONO VISUALIZZATI
                let datadinascita_split = this.form.datadinascita.split('-');
                let user = {
                    statocivile: this.form.statocivile,
                    nome: this.form.nome,
                    cognome: this.form.cognome,
                    datadinascita: datadinascita_split[2] + '/' + datadinascita_split[1] + '/' + datadinascita_split[0],
                    codicefiscale: this.form.codicefiscale,
                    telefono: this.form.telefono,
                    codiceiban: this.form.codiceiban,
                    titolarecontocorrente: this.form.titolarecontocorrente,
                    indirizzo: this.form.indirizzo,
                    ncivico: this.form.ncivico,
                    provincia: this.form.provincia,
                    codicepostale: this.form.codicepostale,
                    localita: this.form.localita,
                    paeseresidenza: this.form.paeseresidenza,
                    targavettura: this.form.targavettura,
                    filialeritiro: this.form.filialeritiro,
                }

                //imposta i dati nello store - USER
                this.$store.dispatch('setUser', user);

                //imposta i dati nello store - EMAIL
                this.$store.dispatch('setUserEmail', this.form.email);

                console.log('patch? ' + this.$store.state.patch.value)

                let car_info = this.$store.state.car.info;

                if (!this.$store.state.patch.value) {
                    //POST - Salva i dati del form dell'utente
                    this.__POST_PROSPECTS();
                } else {
                    //PATCH - Salva i dati del form dell'utente
                    this.__PATCH_PROSPECTS();
                }

            }
        },

        //Validazione campi
        isNumber: function (e) {
            let ASCIICode = (e.which) ? e.which : e.keyCode;
            if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
                e.preventDefault();
            }
        },
        isEmail: function (email) {
            let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return regex.test(email);
        },
        isFiscalCode: function (codicefiscale) {
            //let regex = /[A-Za-z]{6}[0-9lmnpqrstuvLMNPQRSTUV]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9lmnpqrstuvLMNPQRSTUV]{2}[A-Za-z]{1}[0-9lmnpqrstuvLMNPQRSTUV]{3}[A-Za-z]{1}/;
            //return codicefiscale.match(regex);  //questo verificava se codice fiscale ITALIANO e corretto
            return codicefiscale.length == 16;
        },
        isIbanCode: function (codiceiban) {
            return codiceiban.length >= 16 && codiceiban.length <= 34;
        },
        isAlphanumeric: function (ipn) {
            let code, i, len;
            for (i = 0, len = ipn.length; i < len; i++) {
                code = ipn.charCodeAt(i);
                if (!(code > 47 && code < 58) && // numeric (0-9)
                    !(code > 64 && code < 91) && // upper alpha (A-Z)
                    !(code > 96 && code < 123)) { // lower alpha (a-z)
                    return false;
                }
            }
            if (ipn.toString().length == 7) {
                return true;
            } else {
                return false;
            }
        },
        //apre e chiude popup Data di assunzione
        openPopupDataAssunzione: function () {
            this.popupIsHidden = false;
        },
        closePopupDataAssunzione: function () {
            this.popupIsHidden = true;
        },
        //al cambio del datore di lavoro compila alcuni campi
        employerChange: function (e) {
            let employer = e.target.value;
            console.log(employer)

            
            if (employer == 'renault') {
                this.form.infotelefonofisso = '06/41561';
                this.form.infoindirizzo = 'Via Tiburtina';
                this.form.infonumerocivico = '1159';
                this.form.infoprovincia = 'RM';
                this.form.infocodicepostale = '00156';
                this.form.infolocalita = 'ROMA';
                this.form.infopaese = 'IT';
                //this.form.infopartitaiva = '05811161008';
            }

            if (employer == 'rci') {
                this.form.infotelefonofisso = '06/417731';
                this.form.infoindirizzo = 'Via Tiburtina';
                this.form.infonumerocivico = '1159';
                this.form.infoprovincia = 'RM';
                this.form.infocodicepostale = '00156';
                this.form.infolocalita = 'ROMA';
                this.form.infopaese = 'IT';
                //this.form.infopartitaiva = '05574741004';
            }

            if (employer == 'rrg') {
                this.form.infotelefonofisso = '06/417781';
                this.form.infoindirizzo = 'Via Tiburtina';
                this.form.infonumerocivico = '1159';
                this.form.infoprovincia = 'RM';
                this.form.infocodicepostale = '00156';
                this.form.infolocalita = 'ROMA';
                this.form.infopaese = 'IT';
                //this.form.infopartitaiva = '05701561002';
            }

            if (employer == 'sodicam') {
                this.form.infotelefonofisso = '06/4070416';
                this.form.infoindirizzo = 'Via Tiburtina';
                this.form.infonumerocivico = '1159';
                this.form.infoprovincia = 'RM';
                this.form.infocodicepostale = '00156';
                this.form.infolocalita = 'ROMA';
                this.form.infopaese = 'IT';
                //this.form.infopartitaiva = '01319991004';
            }
        },
        //toggle accordion form
        toggleForm: function (e) {
            //console.log(e)
            let $this = $(e.target);
            if ($this.prop("tagName") != 'DT') {
                $this = $(e.target).parents('dt');
            }
            if (!$this.hasClass('active')) {
                if ($this.hasClass('clickable')) {
                    $this.addClass('active');
                    $this.next().slideDown('fast');
                }
            } else {
                $this.removeClass('active');
                $this.next().slideUp('fast');
            }
        },
        //nasconde spinner e riabilita pulsante invio form
        resetButtonSpinner: function () {
            this.showLoader = false;
            this.disableSubmit = false;
        },
        //abilita il prossimo step
        nextStep: function (num_step) {
            let _ENABLED_NEXT_STEP = false;

            if (num_step == 1) {
                this.error.ipn = !this.form.ipn;
                this.error.ipnvalido = !this.isAlphanumeric(this.form.ipn);
                this.error.email = !this.form.email;
                this.error.emailnonvalida = !this.isEmail(this.form.email);
                this.error.confermaemailnonvalida = !this.isEmail(this.form.confermaemail);
                this.error.confermaemail = !this.form.confermaemail;
                this.error.emailcorrispondenti = this.form.email != this.form.confermaemail;
                this.error.statocivile = !this.form.statocivile;
                this.error.cognome = !this.form.cognome;
                this.error.nome = !this.form.nome;
                this.error.datadinascita = !this.form.datadinascita;
                this.error.codicefiscale = !this.form.codicefiscale;
                this.error.codicefiscalevalido = !this.isFiscalCode(this.form.codicefiscale);
                this.error.telefono = !this.form.telefono;
                if (!(this.error.ipn || this.error.ipnvalido || this.error.email || this.error.confermaemail || this.error.emailnonvalida || this.error.confermaemailnonvalida || this.error.emailcorrispondenti || this.error.statocivile || this.error.cognome || this.error.nome || this.error.datadinascita || this.error.codicefiscale || this.error.codicefiscalevalido || this.error.telefono)) {
                    _ENABLED_NEXT_STEP = true;
                }
            }

            if (num_step == 2) {
                this.error.nucleostatocivile = !this.form.nucleostatocivile;
                if (!this.error.nucleostatocivile) {
                    _ENABLED_NEXT_STEP = true;
                }
            }

            if (num_step == 3) {
                this.error.tipoabitazione = !this.form.tipoabitazione;
                this.error.vivoquidaanni = !this.form.vivoquidaanni;
                this.error.indirizzo = !this.form.indirizzo;
                this.error.ncivico = !this.form.ncivico;
                if (this.form.paeseresidenza == 'IT') {
                    this.error.provincia = !this.form.provincia;
                }
                this.error.codicepostale = !this.form.codicepostale;
                this.error.localita = !this.form.localita;
                this.error.paeseresidenza = !this.form.paeseresidenza;

                if (this.form.checkdomiciliofiscale) {
                    //Residenza 2 (domicilio)
                    this.error.tipoabitazione2 = !this.form.tipoabitazione2;
                    this.error.vivoquidaanni2 = !this.form.vivoquidaanni2;
                    this.error.indirizzo2 = !this.form.indirizzo2;
                    this.error.ncivico2 = !this.form.ncivico2;
                    if (this.form.paeseresidenza2 == 'IT') {
                        this.error.provincia2 = !this.form.provincia2;
                    }
                    this.error.codicepostale2 = !this.form.codicepostale2;
                    this.error.localita2 = !this.form.localita2;
                    this.error.paeseresidenza2 = !this.form.paeseresidenza2;
                }

                if (!(this.error.tipoabitazione || this.error.vivoquidaanni || this.error.indirizzo || this.error.ncivico || this.error.provincia || this.error.codicepostale || this.error.localita || this.error.paeseresidenza || this.error.tipoabitazione2 || this.error.vivoquidaanni2 || this.error.indirizzo2 || this.error.ncivico2 || this.error.provincia2 || this.error.codicepostale2 || this.error.localita2 || this.error.paeseresidenza2)) {
                    _ENABLED_NEXT_STEP = true;
                }
            }

            if (num_step == 4) {
                this.error.tipodidocumento = !this.form.tipodidocumento;
                this.error.numerodocumento = !this.form.numerodocumento;
                this.error.datadirilascio = !this.form.datadirilascio;
                this.error.rilasciatoda = !this.form.rilasciatoda;
                this.error.paesedirilascio = !this.form.paesedirilascio;
                this.error.luogodirilascio = !this.form.luogodirilascio;
                this.error.datadiscadenza = !this.form.datadiscadenza;
                if (!(this.error.tipodidocumento || this.error.numerodocumento || this.error.datadirilascio || this.error.rilasciatoda || this.error.paesedirilascio || this.error.luogodirilascio || this.error.datadiscadenza)) {
                    _ENABLED_NEXT_STEP = true;
                }
            }

            if (num_step == 5) {
                this.error.infoprofessione = !this.form.infoprofessione;
                this.error.infodatoredilavoro = !this.form.infodatoredilavoro;
                this.error.infoindirizzo = !this.form.infoindirizzo;
                this.error.infonumerocivico = !this.form.infonumerocivico;
                if (this.form.infopaese == 'IT') {
                    this.error.infoprovincia = !this.form.infoprovincia;
                }
                this.error.infocodicepostale = !this.form.infocodicepostale;
                this.error.infolocalita = !this.form.infolocalita;
                this.error.infopaese = !this.form.infopaese;
                this.error.infodatadiassunzione = !this.form.infodatadiassunzione;
                if (!(this.error.infoprofessione || this.error.infodatoredilavoro || this.error.infoindirizzo || this.error.infonumerocivico || this.error.infoprovincia || this.error.infocodicepostale || this.error.infolocalita || this.error.infopaese || this.error.infodatadiassunzione)) {
                    _ENABLED_NEXT_STEP = true;
                }
            }

            if (num_step == 6) {
                this.error.titolarecontocorrente = !this.form.titolarecontocorrente;
                this.error.codiceiban = !this.form.codiceiban;
                this.error.codiceibanvalido = !this.isIbanCode(this.form.codiceiban);
                if (!(this.error.titolarecontocorrente || this.error.codiceiban || this.error.codiceibanvalido)) {
                    _ENABLED_NEXT_STEP = true;
                }
            }

            if (num_step == 7) {
                this.error.filialeritiro = !this.form.filialeritiro;
                if (!(this.error.filialeritiro)) {
                    _ENABLED_NEXT_STEP = true;
                }
            }

            if (num_step == 8) {
                this.error.checktrattamentodati = !this.form.checktrattamentodati;
                this.error.radiocommercialivses = this.form.radiocommercialivses != 'accetto';
                this.error.radiocommercialivsterzi = this.form.radiocommercialivsterzi != 'accetto';
                if (!(this.error.checktrattamentodati || this.error.radiocommercialivses || this.error.radiocommercialivsterzi)) {
                    _ENABLED_NEXT_STEP = true;
                }
            }

            if (num_step == 9) {
                this.error.checkcondizionigenerali = !this.form.checkcondizionigenerali;
                this.error.checkcessionedelcredito = !this.form.checkcessionedelcredito;
                this.error.checkregolamento = !this.form.checkregolamento;
                if (!(this.error.checkcondizionigenerali || this.error.checkcessionedelcredito || this.error.checkregolamento)) {
                    _ENABLED_NEXT_STEP = true;
                }
            }

            if (_ENABLED_NEXT_STEP) {
                //self
                let $step_form = $('#step_form-' + num_step);
                $step_form.removeClass('active');
                $step_form.next().slideUp('fast');
                $step_form.find('.accordion-step span').addClass('hide');
                $step_form.find('.accordion-step .modify-form-step').addClass('active_step');

                //next
                let next_step = num_step + 1;
                let $step_form_next = $('#step_form-' + next_step);
                $step_form_next.addClass('active').addClass('clickable');
                $step_form_next.next().slideDown('fast');
            }
        }
    }
}
</script>
