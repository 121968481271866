<template>
<div id="user_info" class="info-box">

    <div class="ct-info-box">

        <div class="row">
            <div class="inner-info-box">

                <!-- {{user_info}} -->
                <div class="inner-info">

                    <div class="ct-info">
                        <div class="subtitle">INFORMAZIONI PERSONALI</div>
                        <div class="title title-no-bold">{{user_info.statocivile}} {{user_info.nome}} {{user_info.cognome}}</div>
                        <div class="title title-no-bold">{{user_info.datadinascita}}</div>
                        <div class="title title-no-bold">{{user_info.codicefiscale}}</div>
                        <div class="title title-no-bold">{{user_info.telefono}}</div>
                    </div>

                    <div class="ct-info">
                        <div class="subtitle">DATI BANCARI</div>
                        <div class="title title-no-bold">{{user_info.titolarecontocorrente}}</div>
                        <div class="title title-no-bold">{{user_info.indirizzo}} {{user_info.ncivico}}</div>
                        <div class="title title-no-bold">{{user_info.codicepostale}} {{user_info.localita}} ({{user_info.provincia}})</div>
                        <div class="title title-no-bold">{{user_info.codiceiban}}</div>
                    </div>

                    <div class="ct-info">
                        <div class="subtitle">ASSICURAZIONE E CONSEGNA</div>
                        <div class="title title-no-bold">{{user_info.targavettura}}</div>
                        <div class="title title-no-bold">{{user_info.filialeritiro}}</div>
                    </div>

                    <button class="btn-1 btn-back-page" @click="backPage">modifica</button>

                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import $ from 'jquery'

export default {
    name: 'UserInfo',

    data: function () {
        return {

        }
    },

    computed: {
        user_info() {
            return this.$store.state.user.info;
        }
    },

    methods: {

        backPage: function () {
            this.$router.push({
                name: 'Form',
            }).then(() => {
                window.scrollTo(0, 0);

                //impostandolo a true viene eseguita la PATCH e non più la post
                // this.$store.dispatch('setPatch', true);

                //menu class
                $('.list-step li a').removeClass('active').removeClass('passed');
                $('.list-step li:nth-child(1) a').addClass('passed');
                $('.list-step li:nth-child(2) a').addClass('active');
            }).catch(() => {
                console.log('error router')
            });
        }
    }
}
</script>

<style scoped>
   
</style>
