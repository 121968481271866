<template>
<div class="footer">

    <div class="ct-scroll-page">
        <div class="row">
            <button class="btn-scroll-top" @click="onScrollTop">torna su</button>
        </div>
    </div>

    <div class="ct-footer-info">
        <div class="row">
            <div class="innder-footer-info">

                <ul class="list-link">
                    <li>numero verde <a href="tel:800904409">800 904 409</a></li>
                    <li>Renault Italia S.p.A. P.IVA 05811161008</li>
                    <li><a href="#">info legali</a></li>
                    <li><a href="#">privacy policy</a></li>
                    <li><a href="#">cookie policy</a></li>
                    <li><a href="#">gestisci i cookie</a></li>
                    <li><a href="#">profilazione</a></li>
                </ul>

                <h3 class="copyright">© Renault 2017-2022</h3>

            </div>
        </div>
    </div>

</div>
</template>

<script>
export default {
    name: 'Footer',

    methods: {
        onScrollTop: function () {
            console.log('scroll')
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }
}
</script>

<style scoped>
   
</style>
