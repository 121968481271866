export default {
    methods: {
        __PATCH_PROSPECTS() {

            let prospectId = this.$store.state.prospect.id;

            let contactId_mobile = this.$store.state.prospect_sub_ids.ids.contactId_mobile;
            let contactId_email = this.$store.state.prospect_sub_ids.ids.contactId_email;
            let addressId_domiciliary = this.$store.state.prospect_sub_ids.ids.addressId_domiciliary;
            let addressId_residential = this.$store.state.prospect_sub_ids.ids.addressId_residential;
            let employmentId = this.$store.state.prospect_sub_ids.ids.employmentId;
            let contactId = this.$store.state.prospect_sub_ids.ids.contactId;
            let addressId = this.$store.state.prospect_sub_ids.ids.addressId;
            let bankAccountId = this.$store.state.prospect_sub_ids.ids.bankAccountId;
            let optinId_condizioni_generali = this.$store.state.prospect_sub_ids.ids.optinId_condizioni_generali;
            let optinId_marketing = this.$store.state.prospect_sub_ids.ids.optinId_marketing;
            let optinId_third_party = this.$store.state.prospect_sub_ids.ids.optinId_third_party;
            let optinid_credit_transfer = this.$store.state.prospect_sub_ids.ids.optinid_credit_transfer;
            let optinid_data_treatment = this.$store.state.prospect_sub_ids.ids.optinid_data_treatment;

            console.log('patch: ' + this.__BASE_URL_CORP + '/prospects')
            // this.axios.get(this.__BASE_URL_CORP + '/prospects', {
            this.axios.patch(this.__BASE_URL_CORP + '/prospects', {     //TODO - rimettere e cancellare sopra
                'x-api-version': 'hydrogen',
                'x-orderId': this.$store.state.user.user_id,
                'x-rci-identity': this.form.ipn,
                'x-scope': 'noldip',
                'prospect': {
                    "prospectId": prospectId,
                    "title": this.form.statocivile,
                    "firstName": this.form.nome,
                    "lastName": this.form.cognome,
                    "nationalIdentifier": {
                        "type": "CF",
                        "value": this.form.codicefiscale
                    },
                    "birthDate": this.form.datadinascita,
                    "maritalStatus": this.form.nucleostatocivile,
                    "dependantFamilyMember": this.form.nucleofamiliariacarico,
                    "householdSize": this.form.nucleocomponenti,
                    "contacts": [
                        {
                            "contactId": contactId_mobile,
                            "contactType": "mobile",
                            "contactClass": "personal",
                            "contactPrefix": "+39",
                            "contactValue": this.form.telefono
                        },
                        {
                            "contactId": contactId_email,
                            "contactType": "email",
                            "contactClass": "personal",
                            "contactValue": this.form.email
                        }
                    ],
                    "addresses": [
                        {
                            "addressId": addressId_residential,
                            "postCode": this.form.codicepostale,
                            "city": this.form.localita,
                            "country": this.form.paeseresidenza,
                            "roadNumber": this.form.ncivico,
                            "province": this.form.provincia,
                            "movedInDuration": this.form.vivoquidaanni,
                            "addressType": this.form.tipoabitazione,
                            "addressStatus": "residential",
                            "addressLine1": this.form.indirizzo
                        },
                        {
                            "addressId": addressId_domiciliary,
                            "postCode": this.form.codicepostale2,
                            "city": this.form.localita2,
                            "country": this.form.paeseresidenza2,
                            "roadNumber": this.form.ncivico2,
                            "province": this.form.provincia2,
                            "movedInDuration": this.form.vivoquidaanni2,
                            "addressType": this.form.tipoabitazione2,
                            "addressStatus": "domiciliary",
                            "addressLine1": this.form.indirizzo2
                        }
                    ],
                    "employments": [{
                        "employmentId": employmentId,
                        "hireDate": this.form.infodatadiassunzione,
                        "employerName": this.form.infodatoredilavoro,
                        "skis": "LD",
                        "monthlyNetIncome": {
                            "currency": "EUR",
                            "amount": this.form.inforetribuzione
                        },
                        "employerContacts": [{
                            "contactId": contactId,
                            "contactType": "landline",
                            "contactClass": "professional",
                            "contactPrefix": "+39",
                            "contactValue": this.form.infotelefonofisso
                        }],
                        "employerAddresses": [{
                            "addressId": addressId,
                            "postCode": this.form.infocodicepostale,
                            "city": this.form.infolocalita,
                            "roadNumber": this.form.infonumerocivico,
                            "province": this.form.infoprovincia,
                            "addressLine1": this.form.infoindirizzo
                        }]
                    }],
                    "birthDayValid": true,
                    "optins": [
                        {
                            "optinId": optinId_condizioni_generali,
                            "required": true,
                            "optinNameId": "000000000007",
                            "optinName": "LLD_GENERAL_CONDITIONS",
                            "optinLabel": "CONDIZIONI GENERALI DI LOCAZIONE",
                            "optinDescription": "Il presente contratto &egrave; disciplinato dalle Condizioni Generali di Locazione che dichiaro di aver ricevuto e comunque di ben conoscere ed accettare integralmente quale parte integrante ed essenziale del rapporto di locazione.",
                            "optinScope": "PRIVATE_LEASE",
                            "optinType": "MANDATORY",
                            "optinLinks": [
                                {
                                    "linkLabel": "Informativa precontrattuale ex art. 49 Codice al consumo",
                                    "url": "http://www.renaultlease.it/download/renault_lease_informativa_precontrattuale_ex_art.49_codice_al_consumo.pdf"
                                },
                                {
                                    "linkLabel": "Condizioni generali di locazione a lungo termine di veicoli",
                                    "url": "http://www.renaultlease.it/download/renault_lease_condizioni_generali_di_noleggio_lungo_periodo.pdf"
                                }
                            ],
                            "optinAllowed": true
                        },
                        {
                            "optinId": optinId_marketing,
                            "required": false,
                            "optinNameId": "000000000010",
                            "optinName": "MARKETING_DATA_TREATMENT",
                            "optinDescription": "Acconsento espressamente e specificamente al trattamento dei miei dati personali da parte di ES Mobility S.r.l. e dei terzi ai quali quest&apos;ultima abbia comunicato i suddetti dati per iniziative marketing, promozionali e commerciali, ricerche di mercato o di soddisfazione della clientela, indicate al paragrafo 2, punto VIII dell&apos;Informativa, con le modalit&agrave; ivi previste.",
                            "optinScope": "PRIVATE_LEASE",
                            "optinType": "MANDATORY",
                            "optinAllowed": true
                        },
                        {
                            "optinId": optinId_third_party,
                            "required": false,
                            "optinNameId": "000000000011",
                            "optinName": "THIRD_PARTY_DATA_TREATMENT",
                            "optinDescription": "Acconsento espressamente e specificatamente  alla comunicazione e all&apos;utilizzo dei miei dati da parte di societ&agrave;  terze, della Rete Convenzionata, nonch&eacute; dagli altri soggetti menzionati nell&apos;Informativa per finalit&agrave; di marketing, promozionale e commerciale, relative a prodotti o servizi propri o di societ&agrave; terze, ovvero i prodotti e servizi di RCI Banque S.A. o di altre societ&agrave; del Gruppo e della cd. Alleanza (ad esempio: Renault Italia S.p.A., Nissan Italia S.r.l.), indicate al paragrafo 2, punto IX dell&apos;Informativa, con le modalit&agrave; ivi previste.",
                            "optinScope": "PRIVATE_LEASE",
                            "optinType": "MANDATORY",
                            "optinAllowed": false
                        },
                        {
                            "optinId": optinid_credit_transfer,
                            "required": true,
                            "optinNameId": "000000000008",
                            "optinName": "CREDIT_TRANSFER",
                            "optinLabel": "CESSIONE DEL CREDITO",
                            "optinDescription": "Preso atto della facolt&agrave; del Locatore di cedere i crediti derivanti dal presente contratto di locazione, ai sensi dell&apos;art. 16, sezione 1, delle Condizioni generali di locazione, il sottoscritto Locatario accetta incondizionatamente che il Locatore ES Mobility S.r.l. ceda tutti i crediti nei confronti del Locatario derivanti dal presente contratto a RCI Banque S.A. Succursale Italiana, Via Tiburtina 1159, 00159, Roma, C.F./Part. IVA 0557474100. In relazione alla suddetta cessione del credito dichiara di essere stato/a preventivamente informato/a in ordine alle disposizioni della normativa sulla tutela delle persone e di altri soggetti rispetto al trattamento dei dati personali ai sensi degli artt. 13 e 14 del Regolamento UE UE n.679 del 2016 e di aver ricevuto la relativa informativa di RCI Banque S.A. Succursale Italiana relativa al trattamento dei dati da parte della stessa (Mod. Info. Cessione Crediti), nonch&eacute; il Modello di Informativa relativo al &ldquo;Codice di condotta per i sistemi informativi gestiti da soggetti privati in tema di crediti al consumo, affidabilit&agrave; e puntualit&agrave; nei pagamenti&rdquo; (Mod. Info.SIC).",
                            "optinScope": "PRIVATE_LEASE",
                            "optinType": "MANDATORY",
                            "optinLinks": [
                                {
                                    "linkLabel": "Informativa sul trattamento dei dati personali - Cessione crediti",
                                    "url": "http://www.renaultlease.it/download/renault_lease_informativa_sul_trattamento_dei_dati_personali_cessione_crediti.pdf"
                                }
                            ],
                            "optinAllowed": true
                        },
                        {
                            "optinId": optinid_data_treatment,
                            "required": true,
                            "optinNameId": "000000000009",
                            "optinName": "DATA_TREATMENT",
                            "optinLabel": "Consenso necessario al trattamento dei dati personali",
                            "optinDescription": "Dichiaro di essere stato preventivamente informato in ordine alle disposizioni della normativa sulla tutela delle persone e di altri soggetti rispetto al trattamento dei dati personali ai sensi degli artt. 13 e 14 del Regolamento UE 679/2016 e di aver ricevuto la relativa Informativa sul trattamento dei dati personali da parte di ES Mobility S.r.l. (Sezione 7 delle Condizioni Generali di Locazione).",
                            "optinScope": "PRIVATE_LEASE",
                            "optinType": "MANDATORY",
                            "optinLinks": [
                                {
                                    "linkLabel": "Informativa sul trattamento dei dati personali - Locazione veicolo",
                                    "url": "http://www.renaultlease.it/download/renault_lease_informativa_sul_trattamento_dei_dati_personali_locazione_veicolo.pdf"
                                }
                            ],
                            "optinAllowed": true
                        }
                    ],
                    "bank-accounts": [{
                        "active": true,
                        "bankAccountId": bankAccountId,
                        "accountHolderName": this.form.titolarecontocorrente,
                        "iban": this.form.codiceiban
                    }],
                    "identity-documents": [{
                        "valid": true,
                        "issuedOrganization": this.form.rilasciatoda,
                        "issuedCountry": this.form.paesedirilascio,
                        "issuedCity": this.form.luogodirilascio,
                        "issueDate": this.form.datadirilascio,
                        "expiryDate": this.form.datadiscadenza,
                        "identityDocumentType": this.form.tipodidocumento,
                        "identityDocumentNumber": this.form.numerodocumento,
                        "identityDocId": this.form.tipodidocumento
                    }]
                }
            })
                .then(response => {
                    console.log(response)

                    // let data = response.data; //rimuovere data
                    let data = response;

                    //PATCH - Salva i dati dell'auto selezionata
                    this.__PATCH_PROPOSAL(prospectId);

                }).catch(error => {
                    console.log(error)
                    this.resetButtonSpinner();
                }).finally(() => {
                    // console.log('fine')
                });
        }
    }
};