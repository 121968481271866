import $ from 'jquery'

export default {
    methods: {
        __GET_PROPOSAL_STATUS(prospectId) {

            let self = this;

            //pulling ogni 5 secondi
            let interval_proposal = setInterval(function () {

                // clearInterval(interval_proposal);   //TODO - eliminare qua

                console.log('get: ' + self.__BASE_URL_CORP + '/customers/' + prospectId + '/proposals/latest/status')
                // self.axios.get(self.__BASE_URL_CORP + '/status')
                    self.axios.get(self.__BASE_URL_CORP + '/customers/' + prospectId + '/proposals/latest/status')    //TODO - rimettere e cancellare sopra
                    .then(response => {
                        console.log(response)

                        clearInterval(interval_proposal);

                        self.resetButtonSpinner();

                        // let data = response.data; //TODO - rimuovere data
                        let data = response;

                        //stato
                        let status = data.status;

                        console.log(status)

                        // se ABORTED -> offerta disallineata (delete prenotazione e redirect home)
                        if (status == 'ABORTED') {

                            self.formInvalidMessageAjax = false;

                            self.__DELETE_PRENOTAZIONE();
                        }

                        // se ERROR -> creata la proposta ma ci sono errori di compilazione, stampa errore sotto il form
                        //             dopodichè non fa più il post ma la PATCH
                        if (status == 'ERROR') {

                            // let data = response.data; //TODO - rimuovere data
                            let data = response;

                            //numero proposta
                            let proposalId = data.proposalId;

                            //impostandolo a true viene eseguita la PATCH e non più la post
                            self.$store.dispatch('setPatch', true);

                            console.log('get: ' + self.__BASE_URL_CORP + '/errors')
                            self.axios.get(self.__BASE_URL_CORP + '/errors', {
                                'x-api-version': 'hydrogen',
                                'customerId': prospectId,
                                'proposalId': proposalId
                            })
                                .then(response => {
                                    console.log(response)

                                    // let data = response.data; //TODO - rimuovere data
                                    let data = response;

                                    self.message.controlloAjax = data.message;
                                    self.formInvalidMessageAjax = true;

                                }).catch(error => {
                                    console.log(error)
                                }).finally(() => { })
                        }

                        // se CREATED -> restituisce il numero di proposta e quindi passare alla pagina sucessiva
                        if (status == 'CREATED') {

                            // let data = response.data; //TODO - rimuovere data
                            let data = response;

                            console.log('proposal id: ' + data.proposalId)

                            //imposta i dati nello store - PROPOSAL ID
                            self.$store.dispatch('setProposalId', data.proposalId);

                            self.formInvalidMessageAjax = false;

                            //passa alla pagina form Upload
                            self.$router.push({
                                name: 'FormUpload'
                            }).then(() => {
                                window.scrollTo(0, 0);

                                //menu class
                                $('.list-step li a').removeClass('active');
                                $('.list-step li:nth-child(2) a').addClass('passed');
                                $('.list-step li:nth-child(3) a').addClass('active');
                            }).catch(() => {
                                console.log('error router')
                            });
                        }

                    }).catch(error => {
                        console.log(error)
                        self.resetButtonSpinner();

                    }).finally(() => { })
            }, 5000);
        }
    }
};