import $ from 'jquery'

export default {
    methods: {
        __PUT_PRENOTAZIONI() {

            let id_prenotazione = this.$store.state.car.id_prenotazione;

            console.log('id prenotazione: ' + id_prenotazione)

            console.log('put: ' + this.__BASE_URL_NODIP + '/prenotazioni/' + id_prenotazione)
            // this.axios.get(this.__BASE_URL_NODIP + '/prenotazioniEnd', {
            this.axios.put(this.__BASE_URL_NODIP + '/prenotazioni/' + id_prenotazione, {       //TODO - rimettere e cancellare sopra
                "id": id_prenotazione
            })
                .then(response => {
                    console.log(response)
                    console.log('fine')

                    //imposta i dati nello store - PRENOTAZIONE CONFERMATA
                    this.$store.dispatch('setConfirmedPrenotation', true);

                    //passa alla pagina Conferma prenotazione
                    this.$router.push({
                        name: 'ConfirmPrenotation'
                    }).then(() => {
                        window.scrollTo(0, 0);

                        //menu class
                        $('.list-step li a').removeClass('active');
                        $('.list-step li:nth-child(3) a').addClass('passed');
                        $('.list-step li:nth-child(4) a').addClass('active');
                    }).catch(() => {
                        console.log('error router')
                    });


                }).catch(error => {
                    console.log(error)

                    //passa alla pagina Errore prenotazione
                    this.$router.push({
                        name: 'ErrorPrenotation'
                    }).then(() => {
                        window.scrollTo(0, 0);

                        //menu class
                        $('.list-step li a').removeClass('active');
                        $('.list-step li:nth-child(3) a').addClass('passed');
                        $('.list-step li:nth-child(4) a').addClass('active');
                    }).catch(() => {
                        console.log('error router')
                    });
                }).finally(() => {
                    // console.log('fine')
                });
        }
    }
};