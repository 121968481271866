export default {
    methods: {
        __POST_PRENOTAZIONE(i) {
            let id_prenotazione = 0;

            console.log('post: ' + this.__BASE_URL_NODIP + '/prenotazioni')
            // this.axios.get(this.__BASE_URL_NODIP + '/prenotazioni', {
            this.axios.post(this.__BASE_URL_NODIP + '/prenotazioni', {  //TODO - rimettere e cancellare sopra
                // prenotazione: {
                offerta: this.cars.varianti_idOfferta[i],
                prenotante: this.user_id
                // }
            })
                .then(response => {
                    console.log(response)

                    // let data = response.data; //rimuovere data
                    let data = response;

                    //ID PRENOTAZIONE
                    id_prenotazione = data.id;

                    this.nextPage(i, id_prenotazione);

                }).catch(error => {
                    console.log(error)

                    //se 404 non ci son più disponibilità per la vettura selezionata
                    if (error.response.status == 404) {
                        //visualizza popup "macchina non disponibile"
                        this.showPopupCar = true;
                    }

                }).finally(() => {
                    // console.log('fine')
                });
        }
    }
};