<template>
<div id="confirm">

    <div class="ct-box">
        <div class="row">
            <div class="inner-ct-box">
                <img :src="car_info.immagine" />

                <div class="info">

                    <div class="ct-info">
                        <div class="subtitle">modello</div>
                        <div class="title">{{car_info.modello}}</div>
                        <div class="title title-no-bold">{{car_info.versione}}</div>
                    </div>

                    <div class="ct-info">
                        <div class="subtitle">Canone</div>
                        <div class="title">{{car_info.canoneMensile}}</div>
                        <div class="price-info">di cui {{car_info.autoNetto}}€ vettura e {{car_info.servizi}}€ servizi</div>
                    </div>

                    <div class="ct-info">
                        <div class="subtitle">opzione</div>
                        <div class="title title-no-bold">{{car_info.opzioni_descrizione}}</div>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="ct-confirm">
        <div class="row">
            <div class="inner-ct-confirm">
                <div class="title">Grazie, il tuo ordine è stato completato con successo!</div>
                <div class="proposta-id">Proposta n. 101267</div>
                <div class="text">Il riepilogo del tuo ordine è stato inviato alla mail <a :href="'mailto:'+ user_email">{{user_email}}</a>. Sarai contattato dalla filiale scelta non appena la vettura sarà pronta per il ritiro.</div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
export default {
    name: 'ConfirmPrenotation',

    components: {

    },

    mounted() {

        //redirect in home se refresh pagina
        if (this.$store.state.confirmedPrenotation.value == false) {
            window.location.replace('/');
        }
    },

    computed: {
        car_info() {
            return this.$store.state.car.info;
        },
        user_email() {
            return this.$store.state.user.email;
        }
    },

    data: function () {
        return {
            errors: [],
        }
    },

    methods: {

    }
}
</script>
