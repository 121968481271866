<template>
<div id="cars_list">

    <div class="row">

        <div class="ct-title-page">
            <h1 class="title-page">Scopri le offerte di noleggio dedicate ai dipendenti del Gruppo Renault</h1>
        </div>

        <div class="ct-car-block">
            <div class="car-block" v-for="(car, index) in cars.cars_list" :key="car.varianti[car.indice_offerta].idOfferta">
                <div class="ct-img-info">
                    <div class="ct-info">
                        <h2 class="car-name">{{ car.veicolo.modello.value }}</h2>
                        <p class="car-info">{{ car.veicolo.versione.value }}</p>
                    </div>
                    <img :src="car.varianti[car.indice_offerta].immagineUrl" alt="" />
                </div>
                <div class="ct-others-info">
                    <p class="price-totals">
                        <span class="canone">canone</span>
                        <input :value="formatEuro(Math.round(car.canone.totaleLordo)) + '/mese'" type="text" disabled />
                    </p>
                    <p class="price-detail">di cui {{ parseFloat(Math.round(car.canone.autoNetto * 1.22)).toFixed(2) }}€ vettura e {{ parseFloat(Math.round(car.servizi.canone)).toFixed(2) }}€ servizi</p>
                    <p class="car-description" v-html="car.descrizione.replace(/\\n/g, '<br/>')"></p>
                    <div class="ct-bottom">
                        <div class="ct-select-qta">
                            <div class="ct-select">
                                <span class="title-little">Opzioni</span>
                                <select :class="'car-color c-'+index" v-model="cars.varianti_idOfferta[index]" @change="optionChange(index, $event)">
                                    <option v-for="varianti in car.varianti" :value="varianti.idOfferta" :key="varianti.idOfferta" :data-disponibilita="varianti.disponibilita" :data-immagine_url="varianti.immagineUrl" :data-id_opzione="varianti.opzioni[0].idOpzione" :data-codice="varianti.opzioni[0].codice" :data-descrizione="varianti.opzioni[0].descrizione" :data-lordo="varianti.opzioni[0].lordo" :data-margine="varianti.opzioni[0].margine" :data-netto="varianti.opzioni[0].netto">
                                        {{ varianti.opzioni[0].descrizione }}
                                    </option>

                                    <!-- <option v-for="opzioni in car.opzioni" :value="opzioni.codice" :key="opzioni.codice" :data-id="opzioni.opzioni_idOpzione" :data-descrizione="opzioni.descrizione" :data-lordo="opzioni.lordo" :data-margine="opzioni.margine" :data-netto="opzioni.netto">
                                    {{ opzioni.descrizione }}
                                </option> -->
                                </select>
                            </div>
                            <div class="ct-qta">
                                <span class="title-little">Q.tà disponibile</span>
                                <span class="title-qta">{{ car.varianti[car.indice_offerta].disponibilita }}</span>
                            </div>
                        </div>
                        <button class="btn-select-car" @click="selectCar(index)">seleziona</button>
                    </div>
                </div>

                <!-- <input v-model="cars.disponibilita[index]" type="hidden" :class="'qta q-'+index"  /> -->
                <!-- <input v-model="cars.veicolo.modello.value[index]" type="hidden" /> -->
            </div>

        </div>

        <Informations />

        <div class="popup" v-if="showPopupCar">
            <div class="inner-popup">
                <span class="title-popup">Attenzione!</span>
                <p class="text-popup">La vettura non è più disponibile</p>
                <div class="wrapper-button">
                    <button @click="closePopupCar()">ok</button>
                </div>
            </div>
        </div>

    </div>

    <Footer />

</div>
</template>

<script>
// @ is an alias to /src
import $ from 'jquery'
// import { mapState } from 'vuex';
import Informations from '@/components/cars/Informations.vue'
import Footer from '@/components/Footer.vue'

import get_offerte from '@/mixins/requests/get_offerte'
// import delete_prenotazione from '@/mixins/requests/delete_prenotazione'
import post_prenotazione from '@/mixins/requests/post_prenotazione'

export default {
    name: 'Home',

    mixins: [get_offerte, post_prenotazione],

    components: {
        Informations,
        Footer
    },

    mounted() {
        //ritorna lista vetture
        this.__GET_OFFERTE();
    },

    created() {
        //entra solo se parametro in router
        this.$watch(() => this.$route.params, (params, previousParams) => {
            //chiamata da DELETE_PRENOTAZIONE
            if (params.action == '__REINIT_OFFERTE') {
                //ritorna lista vetture
                this.__GET_OFFERTE();
            }
        })
    },

    data: function () {
        return {
            errors: [],
            cars: {
                cars_list: '',
                indice_offerta: [],

                varianti_idOfferta: [],
                varianti_disponibilita: [],
                varianti_immagineUrl: [],
                varianti_opzioni_idOpzione: [],
                varianti_opzioni_codice: [],
                varianti_opzioni_descrizione: [],
                varianti_opzioni_lordo: [],
                varianti_opzioni_margine: [],
                varianti_opzioni_netto: [],

                canaleVendita: [],
                canone_idCanone: [],
                canone_autoLordo: [],
                canone_autoNetto: [],
                canone_totaleLordo: [],
                chilometri: [],
                codice: [],
                codiceConcessionario: [],
                codiceProntuario: [],
                codiceAggregazioneOfferta: [],
                descrizione: [],
                durata: [],
                ecoBonus: [],
                ipt: [],
                ivaVeicolo: [],
                mss: [],
                mssPfu: [],
                nettoConOpzioni: [],
                nettoVeicolo: [],
                sconto_idSconto: [],
                sconto_importo: [],
                sconto_percentuale: [],
                servizi_idServizi: [],
                servizi_canone: [],
                servizi_pcl: [],
                servizi_pdu: [],
                servizi_prontuario: [],
                tassoOfferta: [],
                validita_fine: [],
                validita_inizio: [],
                valoreResiduo_correzione: [],
                valoreResiduo_importo: [],
                valoreResiduo_percentuale: [],
                veicolo_idVeicolo: [],
                veicolo_alimentazione_key: [],
                veicolo_alimentazione_value: [],
                veicolo_brand: [],
                veicolo_modello_key: [],
                veicolo_modello_value: [],
                veicolo_potenza: [],
                veicolo_utilizzo: [],
                veicolo_versione_key: [],
                veicolo_versione_value: [],
            },
            countdown_car: 60, //60 minuti
            user_id: '',
            showPopupCar: false
        }
    },

    //   computed: {
    //       counta() {
    //           return this.$store.state.count;
    //       }
    //   },

    methods: {

        formatEuro: function (price) {
            let euroEU = Intl.NumberFormat("de-DE", {
                style: "currency",
                currency: "EUR",
            });
            return euroEU.format(price);
        },

        optionChange: function (index, e) {
            let data = e.target.options[e.target.options.selectedIndex].dataset; //legge i data
            let nuovo_indice = e.target.options.selectedIndex;
            let idOfferta = e.target.value;
            let disponibilita = data.disponibilita;
            let immagineUrl = data.immagine_url;
            let idOpzione = data.id_opzione;
            let codice = data.codice;
            let descrizione = data.descrizione;
            let lordo = data.lordo;
            let margine = data.margine;
            let netto = data.netto;

            //update view
            this.cars.cars_list[index].indice_offerta = nuovo_indice;
            this.cars.cars_list[index].varianti[nuovo_indice].idOfferta = idOfferta;
            this.cars.cars_list[index].varianti[nuovo_indice].disponibilita = disponibilita;
            this.cars.cars_list[index].varianti[nuovo_indice].immagineUrl = immagineUrl;

            //update data
            this.cars.indice_offerta[index] = nuovo_indice;
            this.cars.varianti_idOfferta[index] = idOfferta;
            this.cars.varianti_disponibilita[index] = disponibilita;
            this.cars.varianti_immagineUrl[index] = immagineUrl;
            this.cars.varianti_opzioni_idOpzione[index] = idOpzione;
            this.cars.varianti_opzioni_codice[index] = codice;
            this.cars.varianti_opzioni_descrizione[index] = descrizione;
            this.cars.varianti_opzioni_lordo[index] = lordo;
            this.cars.varianti_opzioni_margine[index] = margine;
            this.cars.varianti_opzioni_netto[index] = netto;

            // console.log(this.cars.varianti_idOfferta[index])
            // console.log(this.cars.varianti_disponibilita[index])
            // console.log(this.cars.varianti_immagineUrl[index])
            // console.log(this.cars.varianti_opzioni_idOpzione[index])
            // console.log(this.cars.varianti_opzioni_codice[index])
            // console.log(this.cars.varianti_opzioni_descrizione[index])
            // console.log(this.cars.varianti_opzioni_lordo[index])
            // console.log(this.cars.varianti_opzioni_margine[index])
            // console.log(this.cars.varianti_opzioni_netto[index])
        },

        selectCar: function (i) {

            //genera e imposta i dati nello store - USER_ID
            this.user_id = 'user_' + Math.floor(Math.random() * 9999999) + 1;
            this.$store.dispatch('setUserId', this.user_id);

            //decrementa la disponibilita
            let disponibilita_dec = this.cars.cars_list[i].varianti[this.cars.cars_list[i].indice_offerta].disponibilita -= 1;
            this.cars.varianti_disponibilita[i] = disponibilita_dec;

            let car = {
                index: i,
                indice_offerta: this.cars.indice_offerta[i],
                //varianti
                idOfferta: this.cars.varianti_idOfferta[i],
                disponibilita: this.cars.varianti_disponibilita[i],
                immagine: this.cars.varianti_immagineUrl[i],
                opzioni_idOpzione: this.cars.varianti_opzioni_idOpzione[i],
                opzioni_codice: this.cars.varianti_opzioni_codice[i],
                opzioni_descrizione: this.cars.varianti_opzioni_descrizione[i],
                opzioni_lordo: this.cars.varianti_opzioni_lordo[i],
                opzioni_margine: this.cars.varianti_opzioni_margine[i],
                opzioni_netto: this.cars.varianti_opzioni_netto[i],
                //stampati
                codiceAggregazioneOfferta: this.cars.codiceAggregazioneOfferta[i],
                modello: this.cars.veicolo_modello_value[i],
                versione: this.cars.veicolo_versione_value[i],
                autoNetto: this.cars.canone_autoNetto[i],
                servizi: this.cars.servizi_canone[i],
                canoneMensile: this.formatEuro(Math.round(this.cars.canone_totaleLordo[i])) + '/mese',
                //altri
                canaleVendita: this.cars.canaleVendita[i],
                canone_idCanone: this.cars.canone_idCanone[i],
                canone_autoLordo: this.cars.canone_autoLordo[i],
                canone_totaleLordo: this.cars.canone_totaleLordo[i],
                chilometri: this.cars.chilometri[i],
                codice: this.cars.codice[i],
                codiceConcessionario: this.cars.codiceConcessionario[i],
                codiceProntuario: this.cars.codiceProntuario[i],
                descrizione: this.cars.descrizione[i],
                durata: this.cars.durata[i],
                ecoBonus: this.cars.ecoBonus[i],
                ipt: this.cars.ipt[i],
                ivaVeicolo: this.cars.ivaVeicolo[i],
                mss: this.cars.mss[i],
                mssPfu: this.cars.mssPfu[i],
                nettoConOpzioni: this.cars.nettoConOpzioni[i],
                nettoVeicolo: this.cars.nettoVeicolo[i],
                sconto_idSconto: this.cars.sconto_idSconto[i],
                sconto_importo: this.cars.sconto_importo[i],
                sconto_percentuale: this.cars.sconto_percentuale[i],
                servizi_idServizi: this.cars.servizi_idServizi[i],
                servizi_pcl: this.cars.servizi_pcl[i],
                servizi_pdu: this.cars.servizi_pdu[i],
                servizi_prontuario: this.cars.servizi_prontuario[i],
                tassoOfferta: this.cars.tassoOfferta[i],
                validita_fine: this.cars.validita_fine[i],
                validita_inizio: this.cars.validita_inizio[i],
                valoreResiduo_correzione: this.cars.valoreResiduo_correzione[i],
                valoreResiduo_importo: this.cars.valoreResiduo_importo[i],
                valoreResiduo_percentuale: this.cars.valoreResiduo_percentuale[i],
                veicolo_idVeicolo: this.cars.veicolo_idVeicolo[i],
                veicolo_alimentazione_key: this.cars.veicolo_alimentazione_key[i],
                veicolo_alimentazione_value: this.cars.veicolo_alimentazione_value[i],
                veicolo_brand: this.cars.veicolo_brand[i],
                veicolo_modello_key: this.cars.veicolo_modello_key[i],
                veicolo_potenza: this.cars.veicolo_potenza[i],
                veicolo_utilizzo: this.cars.veicolo_utilizzo[i],
                veicolo_versione_key: this.cars.veicolo_versione_key[i]
            }

            //imposta i dati nello store - OFFERTA 
            this.$store.dispatch('setCar', car);

            //Impegna un veicolo di un'offerta per un tempo limitato
            this.__POST_PRENOTAZIONE(i);
        },

        //va alla pagina form - chiamata al sucess della POST PRENOTAZIONE
        nextPage: function (i, id_prenotazione) {

            //imposta i dati nello store - ID_PRENOTAZIONE
            this.$store.dispatch('setIdPrenotation', id_prenotazione);
            console.log('id prenotazione: ' + this.$store.state.car.id_prenotazione)

            //imposta i dati nello store - COUNTDOWN
            this.$store.dispatch('setCountdownCar', this.countdown_car);

            this.$router.push({
                name: 'Form',
                // params: {
                //     id: 123
                // }
            }).then(() => {
                // console.log(this.$route.params.id)
                window.scrollTo(0, 0);

                //menu class
                $('.list-step li a').removeClass('active');
                $('.list-step li:nth-child(1) a').addClass('passed');
                $('.list-step li:nth-child(2) a').addClass('active');
            }).catch(() => {
                console.log('error router')
            });
        },

        //chiude popup Macchina non disponibile
        closePopupCar: function () {
            this.showPopupCar = false;
        }
    }
}
</script>
