export default {
    methods: {
        __GET_OFFERTE() {

            console.log('get: ' + this.__BASE_URL_NODIP + '/offerte')

            this.axios.get(this.__BASE_URL_NODIP + '/offerte')
                //this.axios.get('json/response.json')     //ONLINE
                .then(response => {
                    console.log(response)

                    // let data = response.data[0].offertaList; //rimuovere data[0]
                    let data = response.offertaList;

                    let new_array = [];
                    data.forEach((item, index) => {

                        //cicla tutte le opzioni e le salva in un array
                        let opzioni = item.opzioni;
                        let opzioni_array = [];
                        opzioni.forEach((item_opzioni, index_opzioni) => {
                            opzioni_array.push({
                                idOpzione: item_opzioni.idOpzione,
                                codice: item_opzioni.codice,
                                descrizione: item_opzioni.descrizione,
                                lordo: item_opzioni.lordo,
                                margine: item_opzioni.margine,
                                netto: item_opzioni.netto
                            });
                        });

                        //verifica se esiste già un elemento dell'array con lo stesso codiceAggregazioneOfferta
                        //per aggiornare il campo varianti con solo i valori che cambiano
                        let found = false;
                        new_array.forEach((item_update, index_update) => {
                            if (new_array[index_update].codiceAggregazioneOfferta == item.codiceAggregazioneOfferta) {
                                found = true;
                                new_array[index_update].varianti.push({
                                    idOfferta: item.idOfferta,
                                    disponibilita: item.disponibilita,
                                    immagineUrl: item.immagineUrl,
                                    opzioni: opzioni_array
                                });
                            }
                        });

                        //se non esiste
                        if (!found) {
                            new_array.push({
                                indice_offerta: 0, //valore aggiunto
                                codiceAggregazioneOfferta: item.codiceAggregazioneOfferta,
                                varianti: [{
                                    idOfferta: item.idOfferta,
                                    disponibilita: item.disponibilita,
                                    immagineUrl: item.immagineUrl,
                                    opzioni: opzioni_array
                                }],
                                canaleVendita: item.canaleVendita,
                                canone: {
                                    idCanone: item.canone.idCanone,
                                    autoLordo: item.canone.autoLordo,
                                    autoNetto: item.canone.autoNetto,
                                    totaleLordo: item.canone.totaleLordo
                                },
                                chilometri: item.chilometri,
                                codice: item.codice,
                                codiceConcessionario: item.codiceConcessionario,
                                codiceProntuario: item.codiceProntuario,
                                descrizione: item.descrizione,
                                durata: item.durata,
                                ecoBonus: item.ecoBonus,
                                ipt: item.ipt,
                                ivaVeicolo: item.ivaVeicolo,
                                mss: item.mss,
                                mssPfu: item.mssPfu,
                                nettoConOpzioni: item.nettoConOpzioni,
                                nettoVeicolo: item.nettoVeicolo,
                                sconto: {
                                    idSconto: item.sconto.idSconto,
                                    importo: item.sconto.importo,
                                    percentuale: item.sconto.percentuale
                                },
                                servizi: {
                                    idServizi: item.servizi.idServizi,
                                    canone: item.servizi.canone,
                                    pcl: item.servizi.pcl,
                                    pdu: item.servizi.pdu,
                                    prontuario: item.servizi.prontuario
                                },
                                tassoOfferta: item.tassoOfferta,
                                validita: {
                                    inizio: item.validita.inizio,
                                    fine: item.validita.fine
                                },
                                valoreResiduo: {
                                    correzione: item.valoreResiduo.correzione,
                                    importo: item.valoreResiduo.importo,
                                    percentuale: item.valoreResiduo.percentuale
                                },
                                veicolo: {
                                    idVeicolo: item.veicolo.idVeicolo,
                                    alimentazione: {
                                        key: item.veicolo.alimentazione.key,
                                        value: item.veicolo.alimentazione.value
                                    },
                                    brand: item.veicolo.brand,
                                    modello: {
                                        key: item.veicolo.modello.key,
                                        value: item.veicolo.modello.value
                                    },
                                    potenza: item.veicolo.potenza,
                                    utilizzo: item.veicolo.utilizzo,
                                    versione: {
                                        key: item.veicolo.versione.key,
                                        value: item.veicolo.versione.value
                                    }
                                }
                            });
                        }
                    });

                    // console.log(new_array)

                    this.cars.cars_list = new_array;

                    new_array.forEach((item, index) => {
                        this.cars.indice_offerta[index] = item.indice_offerta;
                        this.cars.canaleVendita[index] = item.canaleVendita;
                        this.cars.canone_idCanone[index] = item.canone.idCanone;
                        this.cars.canone_autoLordo[index] = item.canone.autoLordo;
                        this.cars.canone_autoNetto[index] = item.canone.autoNetto;
                        this.cars.canone_totaleLordo[index] = item.canone.totaleLordo;
                        this.cars.chilometri[index] = item.chilometri;
                        this.cars.codice[index] = item.codice;
                        this.cars.codiceConcessionario[index] = item.codiceConcessionario;
                        this.cars.codiceProntuario[index] = item.codiceProntuario;
                        this.cars.codiceAggregazioneOfferta[index] = item.codiceAggregazioneOfferta;
                        this.cars.descrizione[index] = item.descrizione;
                        //replace |n con <br/>
                        // item.descrizione = item.descrizione.replace(/\\n/g, '<br/>');
                        this.cars.durata[index] = item.durata;
                        this.cars.ecoBonus[index] = item.ecoBonus;
                        this.cars.ipt[index] = item.ipt;
                        this.cars.ivaVeicolo[index] = item.ivaVeicolo;
                        this.cars.mss[index] = item.mss;
                        this.cars.mssPfu[index] = item.mssPfu;
                        this.cars.nettoConOpzioni[index] = item.nettoConOpzioni;
                        this.cars.nettoVeicolo[index] = item.nettoVeicolo;
                        this.cars.sconto_idSconto[index] = item.sconto.idSconto;
                        this.cars.sconto_importo[index] = item.sconto.importo;
                        this.cars.sconto_percentuale[index] = item.sconto.percentuale;
                        this.cars.servizi_idServizi[index] = item.servizi.idServizi;
                        this.cars.servizi_canone[index] = item.servizi.canone;
                        this.cars.servizi_pcl[index] = item.servizi.pcl;
                        this.cars.servizi_pdu[index] = item.servizi.pdu;
                        this.cars.servizi_prontuario[index] = item.servizi.prontuario;
                        this.cars.tassoOfferta[index] = item.tassoOfferta;
                        this.cars.validita_fine[index] = item.validita.fine;
                        this.cars.validita_inizio[index] = item.validita.inizio;
                        this.cars.valoreResiduo_correzione[index] = item.valoreResiduo.correzione;
                        this.cars.valoreResiduo_importo[index] = item.valoreResiduo.importo;
                        this.cars.valoreResiduo_percentuale[index] = item.valoreResiduo.percentuale;
                        this.cars.veicolo_idVeicolo[index] = item.veicolo.idVeicolo;
                        this.cars.veicolo_alimentazione_key[index] = item.veicolo.alimentazione.key;
                        this.cars.veicolo_alimentazione_value[index] = item.veicolo.alimentazione.value;
                        this.cars.veicolo_brand[index] = item.veicolo.brand;
                        this.cars.veicolo_modello_key[index] = item.veicolo.modello.key;
                        this.cars.veicolo_modello_value[index] = item.veicolo.modello.value;
                        this.cars.veicolo_potenza[index] = item.veicolo.potenza;
                        this.cars.veicolo_utilizzo[index] = item.veicolo.utilizzo;
                        this.cars.veicolo_versione_key[index] = item.veicolo.versione.key;
                        this.cars.veicolo_versione_value[index] = item.veicolo.versione.value;

                        //cicla tutte le varianti
                        let varianti = item.varianti;
                        varianti.forEach((item_varianti, index_varianti) => {
                            //imposta la prima opzione come default
                            if (index_varianti == 0) {
                                this.cars.varianti_idOfferta[index] = item_varianti.idOfferta;
                                this.cars.varianti_disponibilita[index] = item_varianti.disponibilita;
                                this.cars.varianti_immagineUrl[index] = item_varianti.immagineUrl;
                                this.cars.varianti_opzioni_idOpzione[index] = item_varianti.opzioni[0].idOpzione;
                                this.cars.varianti_opzioni_codice[index] = item_varianti.opzioni[0].codice;
                                this.cars.varianti_opzioni_descrizione[index] = item_varianti.opzioni[0].descrizione;
                                this.cars.varianti_opzioni_lordo[index] = item_varianti.opzioni[0].lordo;
                                this.cars.varianti_opzioni_margine[index] = item_varianti.opzioni[0].margine;
                                this.cars.varianti_opzioni_netto[index] = item_varianti.opzioni[0].netto;
                            }
                        });
                    });

                }).catch(error => {
                    console.log(error)
                }).finally(() => {
                    //console.log('fine')
                    //importa il file quando e stato generato l'html (NON serve piu)
                    // import('@/assets/js/select-custom.js');
                })
        }
    }
};