export default {
    methods: {
        __PATCH_PROPOSAL(prospectId) {

            let proposalId = this.$store.state.proposal.id;

            let car_info = this.$store.state.car.info;

            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();
            today = yyyy + '-' + mm + '-' + dd;

            console.log('patch: ' + this.__BASE_URL_CORP + '/customers/' + prospectId + '/proposals/' + proposalId)
            // this.axios.get(this.__BASE_URL_CORP + '/proposals/', {
            this.axios.patch(this.__BASE_URL_CORP + '/customers/' + prospectId + '/proposals/' + proposalId, {     //TODO - rimettere e cancellare sopra
                'x-api-version': 'hydrogen',
                'x-orderId': this.$store.state.user.user_id,
                'prospectId': prospectId,
                'proposalId': proposalId,
                'proposal': {
                    "customer": {
                        "customerId": prospectId
                    },
                    "dealer": {
                        "dealerExtId": "83013034_001",
                        "dealerIntId": "13034"
                    },
                    "offer": {
                        "callerId": "NOLDIP",
                        "offerId": this.$store.state.user.user_id,
                        "marketType": "P",
                        "startOfferDate": car_info.validita_inizio,
                        "offerCurrency": "EUR",
                        "vehicleNumber": "1",
                        "product": {
                            "productId": "RLLDVN"
                        },
                        "offerDetail": {
                            "currency": "EUR",
                            "duration": car_info.durata,
                            "mileage": car_info.chilometri,
                            "mileageType": "TOTAL",
                            "monthlyPaymentWoServices": {
                                "netAmount": car_info.autoNetto
                            },
                            "monthlyPayment": {
                                "netAmount": car_info.servizi
                            },
                            "buyBack": {
                                "netAmount": car_info.valoreResiduo_importo,
                                "percentValue": car_info.valoreResiduo_percentuale
                            },
                            "financialPlan": {
                                "financialPlanId": car_info.codiceProntuario + '_' + car_info.canaleVendita
                            },
                            "rates": [
                                {
                                    "rateValue": car_info.tassoOfferta,
                                    "typeRate": "SCALE"
                                }
                            ],
                            "numberOfMonthlyPayment": car_info.durata,
                            "deposit": {
                                "netAmount": "0"
                            }
                        },
                        "services": [],
                        "vehicle": {
                            "brand": {
                                "brandId": "RENA",
                                "brandName": "RENAULT"
                            },
                            "vehicleType": "NEW",
                            "engineType": car_info.veicolo_alimentazione_value,
                            "engineId": car_info.veicolo_alimentazione_key,
                            "power": "0",
                            "powerKw": car_info.veicolo_potenza,
                            "powerCh": "0",
                            "capacity": "0",
                            "vehicleModelShortId": car_info.veicolo_modello_key,
                            "vehicleModelLabel": car_info.modello,
                            "vehicleVersionId": car_info.veicolo_versione_key,
                            "vehicleVersionLabel": car_info.versione,
                            "vehicleDiscount": [
                                {
                                    "netAmount": car_info.sconto_importo,
                                    "percentValue": car_info.sconto_percentuale
                                }
                            ],
                            "vehiclePriceWoOption": {
                                "netAmount": car_info.nettoVeicolo
                            },
                            "vehicleOptions": [
                                {
                                    "optionId": car_info.opzioni_codice,
                                    "optionType": "OPTIONS",
                                    "optionLabel": car_info.opzioni_descrizione,
                                    "optionAmount": {
                                        "netAmount": car_info.opzioni_netto,
                                        "vatAmount": car_info.opzioni_lordo,
                                        "vatValue": car_info.opzioni_margine
                                    }
                                }
                            ],
                            "vehicleTaxes": [
                                {
                                    "netAmount": car_info.ipt,
                                    "taxType": "IPT"
                                },
                                {
                                    "netAmount": car_info.mssPfu,
                                    "taxType": "MSS"
                                },
                                {
                                    "netAmount": car_info.mss,
                                    "taxType": "IMPOSTA"
                                }
                            ],
                            "vehicleUtilityType": car_info.veicolo_utilizzo,
                            "vehiclePrice": [
                                {
                                    "netAmount": car_info.nettoVeicolo,
                                    "vatValue": car_info.ivaVeicolo,
                                    "vatPercent": "22.00",
                                    "priceType": "SALESPRICE"
                                }
                            ],
                            "vehicleTotalOption": {
                                "netAmount": car_info.nettoConOpzioni,
                                "optionType": "OPTIONS"
                            }
                        }
                    },
                    "startProposalDate": today
                }
            })
                .then(response => {
                    console.log(response)

                    //Ritorna lo stato della creazione proposta
                    this.__GET_PROPOSAL_STATUS(prospectId);

                }).catch(error => {
                    console.log(error)
                    this.resetButtonSpinner();
                }).finally(() => {
                    // console.log('fine')
                });
        }
    }
};