
export default {
    methods: {
        __GET_ALLEGATI(proposalId, formData, documents_info, document_type, index) {
            console.log('get: ' + this.__BASE_URL_NODIP + '/allegati')
            // this.axios.get(this.__BASE_URL_NODIP + '/allegati/', {
                this.axios.post(this.__BASE_URL_NODIP + '/allegati/', {    //TODO - rimettere e cancellare sopra
                file: formData
            }, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                console.log(response)

                // let data = response.data; //TODO - rimuovere data
                let data = response;

                //url dell'uplaod
                let url = data._links.self.href;

                //match documentType da json con document_type passato nella funzione
                let documentId = '';
                let documentType = '';
                documents_info.forEach(function (el) {
                    if (el.documentType == document_type) {
                        documentId = documents_info[index].documentId;
                        documentType = documents_info[index].documentType;

                        console.log(document_type + ' ' + documentId + ' ' + documentType)
                    }
                });

                //Salva il documento
                this.__PATCH_DOCUMENTI(url, documentId, documentType, proposalId, documents_info, index);

            }).catch(error => {
                console.log(error)
            }).finally(() => {
                // console.log('fine')
            });
        }
    }
};