<template>
<div id="error">

    <div class="ct-error">
        <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="60" cy="60" r="60" fill="#C7343A" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M60.2756 64.5184L85.7315 89.9742L91.3883 84.3173L65.9325 58.8615L90.6812 34.1128L85.0244 28.4559L60.2756 53.2046L34.8198 27.7488L29.163 33.4057L54.6188 58.8615L28.4558 85.0244L34.1127 90.6813L60.2756 64.5184Z" fill="white" />
        </svg>

        <div class="title-error">Ci spiace, qualcosa è andato storto!</div>
        <div class="text-error">Per favore, torna indietro e riprova oppure contattaci al seguente indirizzo mail: <a href="mailto:prova@mail.com">prova@mail.com</a></div>

        <button class="btn-1 btn-back-page" @click="backPage">torna indietro</button>

    </div>

</div>
</template>

<script>
import $ from 'jquery'

export default {
    name: 'ErrorPrenotation',

    components: {

    },

    mounted() {
        //redirect in home se refresh pagina
        if (this.$store.state.confirmedPrenotation.value == true) {
            window.location.replace('/');
        }
    },

    data: function () {
        return {
            errors: []
        }
    },

    methods: {

        backPage: function () {
            this.$router.push({
                name: 'Home',
                params: {
                    action: '__REINIT_OFFERTE'
                }
            }).then(() => {
                window.scrollTo(0, 0);
                //menu class
                $('.list-step li a').removeClass('active').removeClass('passed');
                $('.list-step li:nth-child(1) a').addClass('active');

            }).catch(() => {
                console.log('error router')
            });
        }
    }
}
</script>
