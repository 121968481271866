import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Form from '../views/Form.vue'
import FormUpload from '../views/FormUpload.vue'
import ConfirmPrenotation from '../views/ConfirmPrenotation.vue'
import ErrorPrenotation from '../views/ErrorPrenotation.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
  {
    path: '/form',
    name: 'Form',
    component: Form
  },
  {
    path: '/form-upload',
    name: 'FormUpload',
    component: FormUpload
  },
  {
    path: '/conferma',
    name: 'ConfirmPrenotation',
    component: ConfirmPrenotation
  },
  {
    path: '/errore',
    name: 'ErrorPrenotation',
    component: ErrorPrenotation
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
