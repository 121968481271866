<template>
<div id="file-drag-drop">

    <CarInfo />

    <UserInfo />

    <div class="row">
        <div class="row-form">

            <div class="contract-ready">
                <div class="inner">
                    <div class="title">IL TUO CONTRATTO è PRONTO!</div>
                    <div class="text">Ecco il contratto con la proposta n. 101267. Scarica e stampa il contratto, firmalo e allega qui sotto la copia firmata.</div>
                    <button class="btn-download download-white" @click="downloadContract()">scarica il contratto</button>
                </div>
                <svg class="triangolo" width="26" height="23" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13 23L0.00962073 0.499998L25.9904 0.5L13 23Z" fill="#EFDF00" />
                </svg>
            </div>

            <div class="title-form-upload">documenti per la pratica</div>
            <div class="text-form-upload">Per completare la pratica di finanziamento abbiamo bisogno dei tuoi documenti. Per favore carica una foto dei documenti richiesti fronte e retro.</div>

            <div class="title-upload">CODICE FISCALE</div>
            <div>
                <form class="drop-form" @drop="handleFileDrop('codfisc', $event)">
                    <span class="drop-files">
                        Trascina e rilascia o <label for="file-upload-1" class="link-drop">seleziona un file</label>
                        <span class="info-file">Formati accettati: jpg, png o pdf sotto i 2MB</span>
                    </span>
                    <input id="file-upload-1" type="file" @change="filesChange('codfisc', $event.target.name, $event.target.files)" accept="image/jpeg, image/jpg, image/png, application/pdf" />

                    <div v-for="(file, key) in files_codfisc" v-bind:key="'file-'+key" class="file-listing">
                        <a class="remove" v-on:click="removeFile('codfisc', key)">X</a>
                        <span class="file-name">{{ file.name }}</span>
                    </div>
                </form>
            </div>

            <div class="title-upload">PATENTE DI GUIDA</div>
            <div>
                <form class="drop-form" @drop="handleFileDrop('patente', $event)">
                    <span class="drop-files">
                        Trascina e rilascia o <label for="file-upload-2" class="link-drop">seleziona un file</label>
                        <span class="info-file">Formati accettati: jpg, png o pdf sotto i 2MB</span>
                    </span>
                    <input id="file-upload-2" type="file" @change="filesChange('patente', $event.target.name, $event.target.files)" accept="image/jpeg, image/jpg, image/png, application/pdf" />

                    <div v-for="(file, key) in files_patente" v-bind:key="'file-'+key" class="file-listing">
                        <a class="remove" v-on:click="removeFile('patente', key)">X</a>
                        <span class="file-name">{{ file.name }}</span>
                    </div>
                </form>

            </div>

            <div class="title-upload">CONTRATTO FIRMATO</div>
            <div>
                <form class="drop-form" @drop="handleFileDrop('contratto', $event)">
                    <span class="drop-files">
                        Trascina e rilascia o <label for="file-upload-3" class="link-drop">seleziona un file</label>
                        <span class="info-file">Formati accettati: jpg, png o pdf sotto i 2MB</span>
                    </span>
                    <input id="file-upload-3" type="file" @change="filesChange('contratto', $event.target.name, $event.target.files)" accept="image/jpeg, image/jpg, image/png, application/pdf" />

                    <div v-for="(file, key) in files_contratto" v-bind:key="'file-'+key" class="file-listing">
                        <a class="remove" v-on:click="removeFile('contratto', key)">X</a>
                        <span class="file-name">{{ file.name }}</span>
                    </div>
                </form>

            </div>

            <div class="info-upload">Dichiaro di essere stato preventivamente informato in ordine alle disposizioni della normativa sulla tutela delle persone e di altri soggetti rispetto al trattamento dei dati personali ai sensi degli artt. 13 e 14 del Regolamento UE 679/2016 e di aver ricevuto la relativa informativa sul trattamento dei dati personali da parte di ES Mobility S.r.l. (Sezione 7 delle Condizione Generali di Locazione).</div>
            <div class="form-control">
                <div class="ct-checkradio"><input id="checkupload" v-model="checkconferma" type="checkbox"><label for="checkupload" class="label-checkbox">Confermo che la richiesta di noleggio si riferisce alla prima o alla seconda vettura in linea con quanto previsto dal regolamento</label></div>
            </div>

            <p class="form-message-errors" v-if="error.formInvalidMessage">Compila tutti i campi</p>

            <button class="btn-1 btn-finalize" v-on:click="submitFiles()" :disabled="files_codfisc.length <= 0 || files_patente.length <= 0 ||files_contratto.length <= 0 || !checkconferma">finalizza il noleggio</button>

        </div>
    </div>

    <Footer />

</div>
</template>

<script>
import UserInfo from '@/components/UserInfo.vue'
import CarInfo from '@/components/CarInfo.vue'
import Footer from '@/components/Footer.vue'

import get_allegati from '@/mixins/requests/get_allegati'
import patch_documenti from '@/mixins/requests/patch_documenti'
import put_prenotazioni from '@/mixins/requests/put_prenotazioni'

export default {
    name: 'FormUpload',

    mixins: [get_allegati, patch_documenti, put_prenotazioni],

    components: {
        UserInfo,
        CarInfo,
        Footer
    },

    data: function () {
        return {
            errors: [],
            error: {
                formInvalidMessage: false
            },
            dragAndDropCapable: false,
            files_codfisc: [],
            files_patente: [],
            files_contratto: [],
            checkconferma: false
        }
    },

    mounted() {

        //redirect in home se refresh pagina
        if (this.$store.state.user.info.length == 0) {
            window.location.replace('/');
        }

        this.dragAndDropCapable = this.determineDragAndDropCapable();
        if (this.dragAndDropCapable) {
            this.bindEvents();
        }
    },

    methods: {

        //File Upload
        bindEvents() {
            ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach(function (evt) {
                var $drop_form = document.getElementsByClassName('drop-form');
                for (var i = 0; i < $drop_form.length; i++) {
                    $drop_form[i].addEventListener(evt, function (e) {
                        e.preventDefault();
                        e.stopPropagation();
                    }.bind(this), false);
                }
            }.bind(this));
        },

        //by drop
        handleFileDrop(type, event) {

            if (type == 'codfisc') {
                this.files_codfisc = [];
                for (let i = 0; i < event.dataTransfer.files.length; i++) {
                    this.files_codfisc.push(event.dataTransfer.files[i]);
                }
            }
            if (type == 'patente') {
                this.files_patente = [];
                for (let i = 0; i < event.dataTransfer.files.length; i++) {
                    this.files_patente.push(event.dataTransfer.files[i]);
                }
            }
            if (type == 'contratto') {
                this.files_contratto = [];
                for (let i = 0; i < event.dataTransfer.files.length; i++) {
                    this.files_contratto.push(event.dataTransfer.files[i]);
                }
            }
        },

        //by input file
        filesChange(type, fieldName, fileList) {
            if (!fileList.length) return;

            if (type == 'codfisc') {
                this.files_codfisc = [];
                for (let i = 0; i < fileList.length; i++) {
                    this.files_codfisc.push(fileList[i]);
                }
            }
            if (type == 'patente') {
                this.files_patente = [];
                for (let i = 0; i < fileList.length; i++) {
                    this.files_patente.push(fileList[i]);
                }
            }
            if (type == 'contratto') {
                this.files_contratto = [];
                for (let i = 0; i < fileList.length; i++) {
                    this.files_contratto.push(fileList[i]);
                }
            }
        },

        removeFile(type, key) {
            if (type == 'codfisc') {
                this.files_codfisc.splice(key, 1);
            }
            if (type == 'patente') {
                this.files_patente.splice(key, 1);
            }
            if (type == 'contratto') {
                this.files_contratto.splice(key, 1);
            }
        },

        determineDragAndDropCapable() {
            var div = document.createElement('div');
            return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window;
        },

        downloadContract() {
            let proposalId = this.$store.state.proposal.id;

            //Ritorna il pdf del contratto
            console.log('post: http://dev.docker.it.grouperci.com/ireport/remote')
            // this.axios.get('http://localhost:3000/contratto',
            this.axios.post('http://dev.docker.it.grouperci.com/ireport/remote', //TODO - rimettere e cancellare sopra
                    '<contratto><numero_proposta_nsi>' + proposalId + '</numero_proposta_nsi></contratto>', {
                        headers: {
                            'Content-Type': 'text/xml'
                        },
                        responseType: 'arraybuffer'
                    }, {
                        responseType: 'arraybuffer'
                    }
                )
                .then(response => {
                    console.log(response)

                    // let data = response.data; //TODO - rimuovere data
                    let data = response;

                    let byteArray = new Uint8Array(data);

                    console.log(byteArray)

                    //ritorna il PDF in byte, apre il pdf in una nuova finestra
                    var file = new Blob([byteArray], {
                        type: 'application/pdf'
                    });

                    var fileURL = URL.createObjectURL(file);
                    window.open(fileURL);

                }).catch(error => {
                    console.log(error)
                }).finally(() => {
                    console.log('fine')
                });
        },

        request__CODICE_FISCALE(proposalId, documents_info, document_type) {
            let formData = new FormData();
            for (var i = 0; i < this.files_codfisc.length; i++) {
                let file = this.files_codfisc[i];
                formData.append('files_codfisc[' + i + ']', file);
                //Ritorna l'url dell'allegato
                this.__GET_ALLEGATI(proposalId, formData, documents_info, document_type, 0);
            }
        },

        request__PATENTE(proposalId, documents_info, document_type) {
            let formData = new FormData();
            for (var i = 0; i < this.files_patente.length; i++) {
                let file = this.files_patente[i];
                formData.append('files_patente[' + i + ']', file);
                //Ritorna l'url dell'allegato
                this.__GET_ALLEGATI(proposalId, formData, documents_info, document_type, 1);
            }
        },

        request__CONTRATTO(proposalId, documents_info, document_type) {
            let formData = new FormData();
            for (var i = 0; i < this.files_contratto.length; i++) {
                let file = this.files_contratto[i];
                formData.append('files_contratto[' + i + ']', file);
                //Ritorna l'url dell'allegato
                this.__GET_ALLEGATI(proposalId, formData, documents_info, document_type, 2);
            }
        },

        submitFiles() {

            if (this.files_codfisc.length > 0 && this.files_patente.length > 0 && this.files_contratto.length > 0 && this.checkconferma) {

                this.error.formInvalidMessage = false;

                let proposalId = this.$store.state.proposal.id;
                let documents_info = [];

                //Ritorna documentId e documentType che servono poi per la patch dei documenti
                console.log('get: ' + this.__BASE_URL_CORP + '/proposals/' + proposalId + '/documents')
                // this.axios.get(this.__BASE_URL_CORP + '/documents', {
                this.axios.get(this.__BASE_URL_CORP + '/proposals/' + proposalId + '/documents', { //TODO - rimettere e cancellare sopra
                        'x-api-version': 'hydrogen',
                        'proposalId': proposalId
                    })
                    .then(response => {
                        console.log(response)

                        // let data = response.data; //TODO - rimuovere data
                        let data = response;

                        //valori di ritorno:
                        // "documentType": "CF", "PA", "RC"
                        // "documentId": id del documento (da passare poi sulla post)
                        //es:
                        // [{
                        //         "documentId": "123",
                        //         "documentType": "CF"
                        //     },
                        //     {
                        //         "documentId": "543",
                        //         "documentType": "PA"
                        //     },
                        //     {
                        //         "documentId": "234",
                        //         "documentType": "RC"
                        //     }
                        // ]

                        //salva i valori in un array
                        data.forEach(function (el) {
                            documents_info.push({
                                documentId: el.documentId,
                                documentType: el.documentType
                            });
                        });

                        console.log(documents_info)

                        //Salva il codice fiscale (prima chiamata documenti)
                        this.request__CODICE_FISCALE(proposalId, documents_info, 'CF');

                    }).catch(error => {
                        console.log(error)
                    }).finally(() => {})

            } else {
                this.error.formInvalidMessage = true;
            }
        }
    }
}
</script>
