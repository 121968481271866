<template>
<div id="app">
    <div id="nav_steps">
        <div class="row-logo">
            <svg width="37" height="48" viewBox="0 0 37 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M29.3418 24L16.4995 48H12.8339L0 24L12.8339 0H16.4995L3.67408 24L14.6709 44.5714L25.6677 24L19.2508 12.0042L21.0878 8.57566L29.3418 24ZM23.8392 0H20.1735L7.33968 24L15.5937 39.4243L17.4307 35.9958L11.0138 23.9915L22.0106 3.42011L33.0074 23.9915L20.1735 48H23.8392L36.673 24L23.8392 0Z" fill="white" /></svg>
        </div>
        <div class="row-step">
            <ul class="list-step">
                <li>
                    <router-link to="/" class="active"><span>Scegli l’auto</span></router-link>
                </li>
                <li>
                    <router-link to="/form"><span>Compila le informazioni</span></router-link>
                </li>
                <li>
                    <router-link to="/form-upload"><span>Carica i documenti</span></router-link>
                </li>
                <li>
                    <router-link to="/conferma"><span>Conferma</span></router-link>
                </li>
            </ul>
        </div>
    </div>

    <keep-alive>
        <router-view />
    </keep-alive>
    
</div>
</template>

<script>
export default {

    data: function () {
        return {
            carInfo: '',
            userInfo: '',
        }
    },

    methods: {

    },
}
</script>

<style lang="scss">
@import './assets/scss/style.scss';
</style>
