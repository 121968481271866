<template>
<div id="car_info" class="info-box">

    <div class="ct-info-box">
        <div class="row">
            <div class="inner-info-box">

                <img :src="car_info.immagine" />

                <!-- {{car_info}} -->
                <div class="inner-info">

                    <div class="info-top">

                        <div class="ct-info">
                            <div class="subtitle">modello</div>
                            <div class="title">{{car_info.modello}}</div>
                            <div class="title title-no-bold">{{car_info.versione}}</div>
                        </div>

                        <div class="ct-info">
                            <div class="subtitle">Canone</div>
                            <div class="title">{{car_info.canoneMensile}}</div>
                            <div class="price-info">di cui {{ parseFloat(Math.round(car_info.autoNetto * 1.22)).toFixed(2) }}€ vettura e {{ parseFloat(Math.round(car_info.servizi)).toFixed(2) }}€ servizi</div>
                        </div>

                        <div class="ct-info">
                            <div class="subtitle">opzione</div>
                            <div class="title title-no-bold">{{car_info.opzioni_descrizione}}</div>
                        </div>

                        <button class="btn-1 btn-back-page" @click="backPage">modifica</button>

                    </div>

                    <div class="info-auto-blocked">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 5L20.6603 19.25H3.33975L12 5Z" stroke="black" stroke-linejoin="round" />
                            <line x1="12" y1="9" x2="12" y2="14" stroke="black" />
                            <circle cx="12" cy="16" r="1" fill="black" /></svg>

                        <div v-if="countDown > 0">
                            <span>La vettura sarà bloccata per <strong>{{countDown}} minuti</strong></span>
                        </div>
                        <div v-else>
                            <span>La vettura è stata sbloccata</span>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="popup" v-if="showPopup">
        <div class="inner-popup">
            <span class="title-popup">Attenzione!</span>
            <p class="text-popup">Mancano 5 minuti alla scadenza della prenotazione</p>
            <div class="wrapper-button">
                <button @click="closePopup()">ok</button>
            </div>
        </div>
    </div>

    <div class="popup-confirm" v-if="showPopupConfirm">
        <div class="inner-popup">
            <span class="title-popup">Attenzione!</span>
            <p class="text-popup">Tornando indietro verrà annullata la prenotazione</p>
            <div class="wrapper-button">
                <button @click="deletePrenotation()">procedi</button>
                <button @click="closePopupConfirm()" class="outline">annulla</button>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import $ from 'jquery'

import delete_prenotazione from '@/mixins/requests/delete_prenotazione'

export default {
    name: 'CarInfo',

    mixins: [delete_prenotazione],

    created: function () {
        this.countDownTimer();
    },

    data: function () {
        return {
            showPopup: false,
            showPopupConfirm: false,
            countDown: this.$store.state.car.countdown.countdown_car
        }
    },

    computed: {
        car_info() {
            return this.$store.state.car.info;
        },
    },

    methods: {

        countDownTimer() {
            if (this.countDown > 0) {
                setTimeout(() => {
                    this.countDown -= 1
                    this.countDownTimer();
                    //aggiorna nello store i secondi rimanenti
                    this.$store.dispatch('setCountdownCar', this.countDown);
                }, 60000);
            } else if (this.countDown == 5) {

                //visualizza popup "scadenza prenotazione"
                this.showPopup = true;

            } else if (this.countDown <= 0) {

                //passa alla pagina home con parametro
                this.$router.push({
                    name: 'Home',
                    params: {
                        action: '__REINIT_OFFERTE'
                    }
                }).then(() => {
                    window.scrollTo(0, 0);
                    //menu class
                    $('.list-step li a').removeClass('active').removeClass('passed');
                    $('.list-step li:nth-child(1) a').addClass('active');

                }).catch(() => {
                    console.log('error router')
                });
            }
        },

        closePopup: function () {
            this.showPopup = false;
        },

        closePopupConfirm: function () {
            this.showPopupConfirm = false;
        },

        deletePrenotation: function () {
            this.showPopupConfirm = false;
            //Elimina la prenotazione e passa alla home
            this.__DELETE_PRENOTAZIONE();
        },

        backPage: function () {
            //visualizza popup "prenotazione verrà annullata"
            this.showPopupConfirm = true;
        }
    }
}
</script>

<style scoped>
   
</style>
